import {PAGE_LIMIT, SearchPage} from "../../components/search-page";
import {ROUTES} from "../../app-config";
import {useState} from "react";
import {MiddlewareFilter, MiddlewareSearchFilters} from "./search-filters";
import {PaginatedResults, useApi} from "../../api";
import {MiddlewareListed} from "./types";
import {generatePath} from "react-router-dom";

export function MiddlewareSearch() {
  const [filter, setFilter] = useState<MiddlewareFilter>({})

  return (

    <SearchPage<MiddlewareListed>
      pageTitle="Middleware"
      pageDescription="Below are middleware technology providers that connect the products companies are building with banks. Use the filters to discover middleware that match your business and technological needs."
      logo="/images/illustration-middleware.svg"
      logoAlt="Abstract illustration to represent middleware"
      generatePath={(m) => generatePath(ROUTES.MIDDLEWARE_DETAIL, { slug: m.slug })}
      clearFilters={() => setFilter({})}
      buildLinkChips={m => {
        const links = [
          {to: generatePath(ROUTES.COMPANY_DETAIL, { slug: m.company.slug}), name: m.company.name}
        ]
        m.sponsors.forEach(s => {
          links.push({to: generatePath(ROUTES.BANK_DETAIL, { slug: s.slug}), name: s.name})
        })
        return links
      }}
      buildInfoChips={m => []}
      filter={filter}
      url="middleware"
    >
      <MiddlewareSearchFilters filter={filter} onChange={setFilter} />
    </SearchPage>

  )
}
