import {Box, Theme, useMediaQuery} from "@mui/material";
import {US_STATES} from "../../constants";
import {CheckboxGroup} from "../../components/checkbox-group";
import {PaginatedResults, useApi} from "../../api";
import {SearchInput} from "../../components/search-input";
import {SlugItem} from "../../types";

export interface BankFilter {
  status?: "public" | "private",
  state?: string
  assets_gt?: string
  assets_lt?: string
  regulator?: string
  fi_type?: string
  program_category?: string
  sponsor_categories?: string
  search?: string
}

const hideAfter = 5
const bottomMargin = 3

export function BankSearchFilters({ filter, onChange }: { filter: BankFilter, onChange: (f: BankFilter) => void }) {
  const { data: regulatorOptions } = useApi<string[]>('financial-institutions/regulators', undefined, true)
  const { data: programCategories } = useApi<SlugItem[]>('programs/categories', undefined, true)
  const { data: sponsorCategories } = useApi<PaginatedResults<SlugItem>>('sponsor-categories', undefined, true)

  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const assetRangeMap: { [key: string]: string } = {
    // assets_gt|assets_lt
    "Under $1B": "|1000000000",
    "$1B - $5B": "1000000000|5000000000",
    "$5B - $10B": "5000000000|10000000000",
    "$10B - $50B": "10000000000|50000000000",
    "Over $50B": "50000000000|"
  }

  return (

    <Box className="filter-fields">

      <SearchInput
        mb={isSm ? undefined : 4}
        value={filter.search || ''}
        onChange={search => onChange({...filter, search})}
      />

      <Box style={{ display: isSm ? "none" : undefined }}>

        <CheckboxGroup
          mb={bottomMargin}
          label="Status"
          items={[{name: "Private", slug: "private"}, {name: "Public", slug: "public"}]}
          onChange={(v) => onChange({...filter, status:  v === "" ? undefined : v as "public" | "private"})}
          value={filter.status}
        />

        {/*<CheckboxGroup*/}
        {/*  mb={bottomMargin}*/}
        {/*  multiSelect*/}
        {/*  label="Type"*/}
        {/*  items={[{name: "Bank", slug: "us_bank"}, {name: "Credit Union", slug: "us_cu"}]}*/}
        {/*  onChange={fi_type => onChange({...filter, fi_type})}*/}
        {/*  value={filter.fi_type}*/}
        {/*/>*/}

        <CheckboxGroup
          mb={bottomMargin}
          label="Sponsor category"
          multiSelect
          items={sponsorCategories?.results || []}
          onChange={sponsor_category => onChange({...filter, sponsor_categories: sponsor_category })}
          value={filter.sponsor_categories}
          hideAfter={hideAfter}
        />

        <CheckboxGroup
          mb={bottomMargin}
          label="Asset size"
          items={Object.keys(assetRangeMap).map(k => ({ name: k, slug: assetRangeMap[k]}))}
          onChange={r => {
            const [gt, lt] = r.split('|')
            onChange({...filter, assets_lt: lt || undefined, assets_gt: gt || undefined})
          }}
          value={`${filter.assets_gt || ''}|${filter.assets_lt || ''}`}
        />

        <CheckboxGroup
          mb={bottomMargin}
          label="Durbin-capped"
          helpText="The Durbin Amendment is a part of the Dodd-Frank Wall Street Reform and Consumer Protection Act that limits transaction fees imposed upon merchants by debit card issuers. The amendment, named after U.S. Senator Richard J. Durbin and introduced in 2010, proposed to restrict these interchange fees, which averaged 44 cents per transaction at the time of proposal. The Durbin Amendment reduced transaction fees to 21 cents plus 0.05% of the transaction value for banks with $10 billion or more in assets."
          items={[{name: "Yes", slug: "10000000000|"}, {name: "No", slug: "|10000000000"}]}
          onChange={r => {
            const [gt, lt] = r.split('|')
            onChange({...filter, assets_lt: lt || undefined, assets_gt: gt || undefined})
          }}
          value={`${filter.assets_gt || ''}|${filter.assets_lt || ''}`}
        />

        <CheckboxGroup
          mb={bottomMargin}
          label="Federal regulator"
          items={(regulatorOptions || []).map(r => ({ name: r, slug: r}))}
          onChange={regulator => onChange({...filter, regulator})}
          value={filter.regulator}
          hideAfter={hideAfter}
        />

        <CheckboxGroup
          mb={bottomMargin}
          label="Program type"
          multiSelect
          items={programCategories}
          onChange={program_category => onChange({...filter, program_category })}
          value={filter.program_category}
          hideAfter={hideAfter}
        />

        <CheckboxGroup
          label="State"
          multiSelect
          value={filter.state || ''}
          items={US_STATES} onChange={(state) => onChange({...filter, state})}
          hideAfter={hideAfter}
        />

      </Box>

    </Box>

  )
}
