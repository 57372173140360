import {useState} from "react";
import {CompanyFilter, CompanySearchFilters} from "./search-filters";
import {PaginatedResults, useApi} from "../../api";
import {CompanyListed} from "./types";
import {generatePath} from "react-router-dom";
import {ROUTES} from "../../app-config";
import {PAGE_LIMIT, SearchPage} from "../../components/search-page";

export function CompanySearch() {
  const [filter, setFilter] = useState<CompanyFilter>({include_vcs: "false"})

  return (

    <SearchPage<CompanyListed>
      pageTitle="Companies"
      pageDescription="Below are companies that embed financial services into their platforms. Use the filters to discover what banks each company partnered with and the middleware they use to connect to the bank."
      logo="/images/illustration-companies.svg"
      logoAlt="Abstract illustration to represent embedded finance companies"
      generatePath={(c) => generatePath(ROUTES.COMPANY_DETAIL, { slug: c.slug })}
      clearFilters={() => setFilter({})}
      buildLinkChips={c => c.programSponsors.map(fi => ({ name: fi.name, to: generatePath(ROUTES.BANK_DETAIL, { slug: fi.slug })}))}
      url="companies"
      filter={filter}
    >
      <CompanySearchFilters filter={filter} onChange={setFilter} />
    </SearchPage>

  )
}
