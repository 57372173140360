import {Box, Button, Grid, Theme, Typography, useMediaQuery} from "@mui/material";
import {useState} from "react";
import {ResponsiveContainer} from "../../components/responsive-container";

export function Testimonials() {
  const [activeTestimonial, setActiveTestimonial] = useState(0)
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const testimonials = [
    {
      quote: "“This is by far the most valuable inventory of fintech banks, companies, and infrastructure that I’ve ever seen. I’m in awe of how the Banking Embedded team compiled all the risk, compliance, market and product data into useful information. Hopefully you find it as useful as I do.”",
      name: "Matt West",
      role: "Former Chief Strategy Officer, MVB Bank",
      linkedIn: "https://www.linkedin.com/in/matthewawest/",
      avatar: "/avatars/matt-west.jpg"
    },
    {
      quote: "“Embedded is a simple yet powerful tool for fintechs and bankers looking to grow via embedded banking and payments”",
      name: "Stuart Cook",
      role: "Former Chief Innovation Officer, Valley Bank",
      linkedIn: "https://www.linkedin.com/in/stuartjcook/",
      avatar: "/avatars/stuart-cook.jpg"
    }
  ]

  return (

    <Box className="bg-light-blue">
      <ResponsiveContainer includePadding>
        <Grid container display="flex" justifyContent="center">
          <Grid item xs={12} md={9.5} display="flex" justifyContent={isSm ? undefined : 'space-between'} flexDirection={isSm ? 'column-reverse' : undefined}>
            <Box style={{ width: "100%", maxWidth: "720px" }}>
              <Typography variant="body1">What our network says about Embedded:</Typography>
              <Box display="flex" width="100%" justifyContent="space-between">
                <Typography variant="h2" mt={3} mb={3} style={{ fontSize: isSm ? "22px" : "28px", lineHeight: isSm ? "32px" : "40px" }}>{testimonials[activeTestimonial]!.quote}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="12px">
                <img src={testimonials[activeTestimonial]!.avatar} width="56" height="56" alt="Person quoted" style={{ borderRadius: "100%" }} />
                <Box>
                  <Typography variant="h5">{testimonials[activeTestimonial]!.name}</Typography>
                  <Typography variant="subtitle1">{testimonials[activeTestimonial]!.role}</Typography>
                </Box>
                <a href={testimonials[activeTestimonial]!.linkedIn} target="_blank" rel="noreferrer" className="icon-button icon-linkedin" style={{ marginLeft: "56px" }}>LinkedIn</a>
              </Box>
              <Box display="flex" justifyContent={isSm ? undefined : "flex-end"} mt={ isSm ? 3 : "-42px" }>
                <Button variant="text" onClick={() => {
                  if (testimonials.length - 1 === activeTestimonial) setActiveTestimonial(0)
                  else setActiveTestimonial(p => p + 1)
                }} style={{ fontWeight: "400", borderBottom: "1px solid #1C1A1F", borderRadius: "0", height: "auto", padding: "0" }}>Next Testimonial</Button>
              </Box>
            </Box>
            <img src="/images/illustration-testimonial.svg" width="83" height="100" alt="Abstract illustration to represent testimonials" style={{ maxWidth: isSm ? "auto" : "100%", maxHeight: isSm ? "80px" : "100px", marginTop: isSm ? undefined : '52px', marginBottom: isSm ? '16px' : undefined }} />
          </Grid>
        </Grid>
      </ResponsiveContainer>
    </Box>

  )
}
