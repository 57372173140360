import {Box, Theme, useMediaQuery} from "@mui/material";
import {CheckboxGroup} from "../../components/checkbox-group";
import {PaginatedResults, useApi} from "../../api";
import {SlugItem} from "../../types";
import {FIListed} from "../banks/types";
import {GEOGRAPHIC_REGIONS, US_STATES} from "../../constants";
import {SearchInput} from "../../components/search-input";

export interface MiddlewareFilter {
  sponsor?: string
  status?: "public" | "private"
  category?: string
  region?: string
  state?: string
  search?: string
}

const hideAfter = 5
const bottomMargin = 3

export function MiddlewareSearchFilters({filter, onChange}: {filter: MiddlewareFilter, onChange: (f: MiddlewareFilter) => void}) {
  const { data: categoryOptions } = useApi<PaginatedResults<SlugItem>>('middleware-categories', )
  const { data: bankOptions } = useApi<PaginatedResults<FIListed>>('financial-institutions', {feature_for_search: "true"})
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (

    <Box className="filter-fields">

      <SearchInput
        mb={isSm ? undefined : 4}
        value={filter.search || ''}
        onChange={search => onChange({...filter, search})}
      />

      <Box style={{ display: isSm ? "none" : undefined }}>

        <CheckboxGroup
          mb={bottomMargin}
          label="Status"
          value={filter.status}
          items={[{name: "Private", slug: "private"}, {name: "Public", slug: "public"}]}
          onChange={(v) => onChange({ ...filter, status: v === "" ? undefined : v as "public" | "private" })}
        />

        <CheckboxGroup
          mb={bottomMargin}
          label="Category"
          multiSelect
          value={filter.category}
          items={categoryOptions?.results} onChange={(category) => onChange({...filter, category})}
          hideAfter={hideAfter}
        />

        <CheckboxGroup
          mb={bottomMargin}
          label="Sponsor"
          multiSelect
          value={filter.sponsor}
          items={bankOptions?.results} onChange={(sponsor) => onChange({...filter, sponsor})}
          hideAfter={hideAfter}
        />

        {/*<CheckboxGroup
          mb={bottomMargin}
          label="Region"
          multiSelect
          value={filter.region}
          items={GEOGRAPHIC_REGIONS} onChange={(region) => onChange({...filter, region})}
          hideAfter={hideAfter}
        />*/}

        {/*<CheckboxGroup
          label="State"
          multiSelect
          value={filter.state || ''}
          items={US_STATES} onChange={(state) => onChange({...filter, state})}
          hideAfter={hideAfter}
        />*/}

      </Box>

    </Box>

  )
}
