import {format} from "date-fns";


export function buildExternalLink(url?: string) {
  if (!url) return ''
  if (url.toLowerCase().startsWith("http")) return url
  return `https://${url}`
}

// https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places
function roundTo(n: number, digits?: number) {
    let r = n
    let negative = false;
    if (digits === undefined) {
        digits = 0;
    }
    if (r < 0) {
        negative = true;
        r = r * -1;
    }
    const multiplicator = Math.pow(10, digits);
    r = parseFloat((r * multiplicator).toFixed(11));
    let s = (Math.round(r) / multiplicator).toFixed(digits);
    if (negative) {
        s = (r * -1).toFixed(digits);
    }
    return s;
}

export function stripEmptyKeys(obj: Record<string, any>): Record<string, any> {
  return Object.keys(obj).reduce((acc, key) => {
    const val = obj[key]
    if (!(val === undefined || val === null || val === '')) acc[key] = val
    return acc;
  }, {} as Record<string, any>);
}

export function dollarsInBillions(amount?: number, fullText?: boolean) {
    if (isNaN(Number(amount))) {
        return "Unknown"
    }
    return `$${roundTo((Number(amount) / 1000000000), fullText ? 1 : 2)}${fullText ? ' billion' : 'B'}`
}

export const formatMoney = (value?: number | string, sigFigs: number = 2): string => {
  if (!value) return ''
  if (typeof value !== 'number') {
      value = Number(value)
  }
  const tiers = [
    { divider: 1e18, suffix: 'E' },
    { divider: 1e15, suffix: 'P' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'B' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'K' }
  ];
    let result: string = "$" + value.toFixed(2).toString()
    for (const tier of tiers) {
        if (value >= tier.divider) {
            result = "$" + (value / tier.divider).toFixed(sigFigs) + tier.suffix;
            break;
        }
    }

    return result
};


export function formatPercent(val: number | string | undefined, precision?: number) {
    if (isNaN(val as unknown as number)) return ''
    else return Number(val).toFixed(precision || 2) + "%"
}


export function formatBoolean(val: boolean | undefined) {
    if (val === undefined) return ''
    return val ? "Yes" : "No"
}


// https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
export const validateEmail = (email?: string): boolean => {
    return Boolean(String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ));
};


// https://stackoverflow.com/a/8358141/3016183
export function formatPhoneNumber(phoneNumberString?: string, masked?: boolean): string {
    const cleaned = ('' + phoneNumberString || '').replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', masked ? "***" : match[3], '-', masked ? "****" : match[4]].join('');
    }
    return '';
}


export function validatePhoneNumber(phoneNumber?: string): boolean {
    if (!phoneNumber) return false
    const phoneRegex = /^(\+?1[-.\s]?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})[-.\s]?[2-9][0-9]{2}[-.\s]?[0-9]{4}$/
    return phoneRegex.test(phoneNumber)
}

export function convertDateString(dateString?: string): string {
    if (!dateString) return ''
    return format(new Date(dateString), "dd MMM yy")
}


export function filterListContains(listToCheck: any[], filterAgainst: any[]): boolean {
  if (filterAgainst.length === 0) return true
  return filterAgainst.every(item => listToCheck.includes(item));
}

export function joinArrayWithCommaAnd(array: string[]): string {
    if (array.length === 0) {
        return "";
    } else if (array.length === 1) {
        return array[0];
    } else if (array.length === 2) {
        return array.join(" and ");
    } else {
        const lastItem = array.pop();
        return array.join(", ") + ", and " + lastItem;
    }
}


export function setTitle(title?: string) {
  const defaultTitle = `Banking Embedded`
  const ogTitle = document.querySelector('head meta[property="og:title"]')

  if (title) {
    document.title = `${title} | Banking Embedded`
    ogTitle?.setAttribute('content', title)
  } else {
    document.title = defaultTitle
    ogTitle?.setAttribute('content', defaultTitle)
  }
}
