import {Box, CircularProgress} from "@mui/material";
import {ResponsiveContainer} from "./responsive-container";

export function PageLoader() {

  return (
    <Box className="absolute-center">
      <ResponsiveContainer includePadding>
        <CircularProgress />
      </ResponsiveContainer>
    </Box>
  )
  
}
