import {useState} from "react";
import {BankFilter, BankSearchFilters} from "./search-filters"
import {ROUTES} from "../../app-config";
import {FIListed} from "./types";
import {SearchPage} from "../../components/search-page";
import {formatMoney} from "../../helpers";
import {generatePath} from "react-router-dom";

export function BankSearch() {
  const [filter, setFilter] = useState<BankFilter>({})

  return (

    <SearchPage<FIListed>
      pageTitle="Sponsors"
      pageDescription="Below are sponsors (e.g. banks) that have partnered with companies to embed financial services into their products. Use the filters to discover sponsors that match your business and technological needs."
      logo="/images/illustration-banks.svg"
      logoAlt="Abstract illustration to represent banks and financial institutions"
      generatePath={(fi) => {
        if (fi.sponsorCategory) {
          return generatePath(ROUTES.SPONSOR_DETAIL, { slug: fi.slug, sponsorType: fi.sponsorCategory.slug})
        }
        return generatePath(ROUTES.BANK_DETAIL, { slug: fi.slug })
      }}
      clearFilters={() => setFilter({})}
      buildLinkChips={fi => fi.companies.map(c => ({ to: generatePath(ROUTES.COMPANY_DETAIL, { slug: c.slug}), name: c.name }))}
      buildInfoChips={fi => fi.totalAssets ? [formatMoney(fi.totalAssets, 1)] : []}
      filter={filter}
      url="financial-institutions"
    >
      <BankSearchFilters filter={filter} onChange={setFilter} />
    </SearchPage>

  )
}
