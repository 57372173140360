import {Box, Theme, useMediaQuery} from "@mui/material";
import {PaginatedResults, useApi} from "../../api";
import {GEOGRAPHIC_REGIONS, US_STATES} from "../../constants";
import {SlugItem} from "../../types";
import {CheckboxGroup} from "../../components/checkbox-group";
import {FIListed} from "../banks/types";
import {TargetMarket} from "./types";
import {MiddlewareListed} from "../middleware/types";
import {SearchInput} from "../../components/search-input";

export interface CompanyFilter {
  privately_held?: "true" | "false",
  category?: string
  region?: string
  state?: string
  bank?: string
  middleware?: string
  target_market?: string
  search?: string
  program_category?: string
  include_vcs?: string
}

export interface CompanySearchProps {
  filter: CompanyFilter
  onChange: (c: CompanyFilter) => void
}

const hideAfter = 5
const bottomMargin = 3

export function CompanySearchFilters({ filter, onChange }: CompanySearchProps) {
  const { data: categoryOptions } = useApi<PaginatedResults<SlugItem>>('company-categories', )
  const { data: bankOptions } = useApi<PaginatedResults<FIListed>>('financial-institutions', {feature_for_search: "true"})
  const { data: middlewareOptions } = useApi<PaginatedResults<MiddlewareListed>>('middleware', {feature_for_search: "true"})
  const { data: programCategories } = useApi<SlugItem[]>('programs/categories', undefined, true)

  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (

    <Box className="filter-fields">

      <SearchInput
        mb={isSm ? undefined : 4}
        value={filter.search || ''}
        onChange={search => onChange({...filter, search})}
      />

      <Box style={{ display: isSm ? "none" : undefined }}>

        <CheckboxGroup
          mb={bottomMargin}
          label="Status"
          value={filter.privately_held}
          items={[{name: "Private", slug: "true"}, {name: "Public", slug: "false"}]}
          onChange={(v) => onChange({ ...filter, privately_held: v === "" ? undefined : v as "true" | "false" })}
        />

        <CheckboxGroup
          mb={bottomMargin}
          label="Category"
          multiSelect
          value={filter.category}
          items={categoryOptions?.results.filter(option => option.name !== 'Venture Capital')}
          onChange={(category) => onChange({...filter, category})}
          hideAfter={hideAfter}
        />

        <CheckboxGroup
          mb={bottomMargin}
          label="Program type"
          multiSelect
          items={programCategories}
          onChange={program_category => onChange({...filter, program_category })}
          value={filter.program_category}
          hideAfter={hideAfter}
        />

        {/*<CheckboxGroup
          mb={bottomMargin}
          label="Region"
          multiSelect
          value={filter.region}
          items={GEOGRAPHIC_REGIONS}
          onChange={(region) => onChange({...filter, region})}
          hideAfter={hideAfter}
        />*/}

        {/*<CheckboxGroup
          mb={bottomMargin}
          label="State"
          multiSelect
          value={filter.state || ''}
          items={US_STATES}
          onChange={(state) => onChange({...filter, state})}
          hideAfter={hideAfter}
        />*/}

        <CheckboxGroup
          mb={bottomMargin}
          label="Middleware"
          multiSelect
          value={filter.middleware}
          items={middlewareOptions?.results}
          onChange={middleware => onChange({...filter, middleware})}
          hideAfter={hideAfter}
        />

        <CheckboxGroup
          label="Sponsor"
          multiSelect
          value={filter.bank}
          items={bankOptions?.results}
          onChange={(bank) => onChange({...filter, bank})}
          hideAfter={hideAfter}
        />

      </Box>

    </Box>

  )
}
