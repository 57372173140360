import {Box, Button, IconButton, Drawer, Theme, useMediaQuery} from "@mui/material";
import {ResponsiveContainer} from "../responsive-container";
import {Link, useLocation} from "react-router-dom";
import {ROUTES} from "../../app-config";
import {ArrowDownward, Close, Menu} from "@mui/icons-material";
import {LogoWithText} from "../icons";
import {createUseStyles} from "react-jss";
import {theme} from "../../theme";
import {useState} from "react";
import {FEATURES_INVESTORS} from "../../features";

const useStyles = createUseStyles({
  activeRoute: {
    background: "url(/images/pattern-active.svg) no-repeat center center",
    backgroundSize: "40px 40px",
    [theme.breakpoints.down('md')]: {
      background: "none"
    }
  },
  mobileDrawer: {
    width: "240px",
    padding: "0 16px",
    height: "100%",
    background: "var(--black)",
    display: "flex", 
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    "& button": {
      marginBottom: "20px",
      color: "var(--white)",
      "&:hover": {
        color: "var(--white)",
      },
    }
  },
  closeButton: {
    position: "fixed",
    top: "16px",
    right: "16px",
    color: "var(--white)"
  }
})

export function Header() {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [open, setOpen] = useState(false)
  const {pathname} = useLocation()
  const classes = useStyles()

  function scrollToFooter() {
    document.getElementById('footer')?.scrollIntoView({behavior: "smooth"})
  }

  const links = (
    <>
      <Link to={ROUTES.ROOT} style={{ display: isSm ? undefined : "none" }}>
        <Button variant='text'>Home</Button>
      </Link>
      <Link to={ROUTES.BANKS} className={pathname.startsWith(ROUTES.BANKS) || pathname.startsWith(ROUTES.SPONSORS) ? classes.activeRoute : undefined} style={{ padding: "4px 0" }}>
        <Button variant='text'>Sponsors</Button>
      </Link>
      <Link to={ROUTES.MIDDLEWARE} className={pathname.startsWith(ROUTES.MIDDLEWARE) ? classes.activeRoute : undefined} style={{ padding: "4px 0" }}>
        <Button variant='text'>Middleware</Button>
      </Link>
      <Link to={ROUTES.COMPANIES} className={pathname.startsWith(ROUTES.COMPANIES) ? classes.activeRoute : undefined} style={{ padding: "4px 0" }}>
        <Button variant='text'>Companies</Button>
      </Link>
      {FEATURES_INVESTORS && (
        <Link to={ROUTES.INVESTORS} className={pathname.startsWith(ROUTES.INVESTORS) ? classes.activeRoute : undefined} style={{ padding: "4px 0" }}>
          <Button variant='text'>Investors</Button>
        </Link>
      )}
      <Link to={ROUTES.INSIGHTS} className={pathname.startsWith(ROUTES.INSIGHTS) ? classes.activeRoute : undefined} style={{ padding: "4px 0" }}>
        <Button variant='text'>Insights</Button>
      </Link>
    </>
  )

  return (

    <Box className="bg-white" style={{background: isSm ? "var(--black)" : undefined}} id="top-nav">
      <ResponsiveContainer>
        <Box display="flex" justifyContent="center" width="100%">
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" style={{padding: isSm ? "20px 0" : "40px 0"}}>
            <Link to={ROUTES.ROOT} style={{display: "flex", maxWidth: isSm ? "140px" : undefined,}}>
              <LogoWithText fill={isSm ? "var(--white)" : "var(--black)"}/>
            </Link>
            {isSm ? (
              <>
                <Box style={{ cursor: "pointer" }} display="flex" onClick={() => setOpen(true)}>
                  <Menu style={{ color: 'var(--white)' }} />
                </Box>
                <Drawer open={open} onClose={() => setOpen(false)} anchor="right" style={{ maxWidth: "100vw", height: "100vh" }}>
                  <IconButton onClick={() => setOpen(false)} className={classes.closeButton}>
                    <Close />
                  </IconButton>
                  <Box className={classes.mobileDrawer}>
                    {links}
                  </Box>
                </Drawer>
              </>
              ) : (
              <>
                <Box display="flex" alignItems="center" gap="16px" style={{ display: isSm ? 'none' : undefined, position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
                  {links}
                </Box>
                <Button variant='text' onClick={scrollToFooter}>
                  <ArrowDownward/>
                  About
                </Button>
              </>
            )}
          </Box>
        </Box>
      </ResponsiveContainer>
    </Box>
  )
}
