import {Box, Checkbox, FormControlLabel, FormLabel, Grid, Radio, TextField, Typography, Theme, useMediaQuery} from "@mui/material";
import {ResponsiveContainer} from "../components/responsive-container";
import {useState} from "react";
import {createUseStyles} from "react-jss";
import {makeApiCall} from "../api";
import {LoadingButton} from "@mui/lab";

const useStyles = createUseStyles({
  formGroup: {
    background: "url(/public/images/bg-light-blue.jpg) var(--light-blue)",
    backgroundSize: "150px 150px",
    padding: "32px",
    marginBottom: "32px",
    borderRadius: "8px"
  }
})

interface FounderQuestionnaire {
  fullName?: string
  role?: string
  email?: string
  phone?: string
  linkedin?: string
  companyName?: string
  companyURL?: string
  companyLinkedInURL?: string
  companyCrunchbaseURL?: string
  companyCountry?: string
  companyState?: string
  capitalRaised?: string
  investors?: string
  hasNMLS?: boolean
  nmlsId?: string
  MSB?: boolean
  typeOfUser?: string
  bankProgramType: string[]
  bankProgramTypeLending?: string
  bankProgramTypeOther?: string
  flowOfFunds?: string
  complianceProgram?: boolean
  complianceOfficer?: string
  bsaAML?: boolean
  terms?: boolean
  termsURL?: string
  certificates: string[]
  bankSponsor?: boolean
  bankSponsorWho?: string
  middleware?: boolean
  middlewareWho?: string
  bankSpoken?: boolean
  bankSpokenWho?: string
  note?: string
}

const bankProgramType: string[] = [
  "Deposit account (checking)",
  "Deposit account (savings)",
  "Debit card",
  "Prepaid card",
  "Credit card",
  "Payments (domestic)",
  "Payments (cross-border)",
  "Merchant acquiring",
  "ATM sponsorship",
  "Lending",
  "Other"
]

const certificates: string[] = [
  "SOC II Type 1",
  "SOC II Type 2",
  "Penetration Test"
]

export function FounderQuestionnaireForm() {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [formState, setFormState] = useState<FounderQuestionnaire>({
    bankProgramType: [],
    certificates: []
  })
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [consentToBeContacted, setConsentToBeContacted] = useState(false)
  const [consentToShareInfo, setConsentToShareInfo] = useState(false)

  function submit() {
    setLoading(true)
    makeApiCall<any>({
      url: "company-questionnaire",
      method: "post",
      params: formState,
      responseHandler: response => {
        if (response.status === 201) setSuccess(true)
      }
    }).catch(console.error).finally(() => setLoading(false))
  }

  if (success) {
    return (
      <Box style={{ background: "url(/public/images/bg-white.jpg) var(--white)", backgroundSize: "150px 150px" }}>
      <ResponsiveContainer includePadding>
        <Grid container display="flex" justifyContent="center">
          <Grid item xs={12} md={9.5} display="flex" flexDirection={isSm ? "column" : undefined} gap={isSm ? "24px" : "56px"} alignItems={isSm ? "flex-start" : "center"} style={{ position: "relative" }}>
            <img src="/images/illustration-founder.png" width={isSm ? "200px" : "290px"} height={isSm ? "200px" : "290px"} alt="Person holding a phone with a banking app on the screen" />
            <Box style={{ maxWidth: "440px" }}>
              <Typography variant="h2">Thank you</Typography>
              <Typography variant="body2" mt={1.5} mb={3}>We received your submission and we'll be in touch within two business days.</Typography>
            </Box>
            <img src="/images/pattern-circle-1.svg" width="160" height="160" alt="Abstract pattern for decoration only" className="pattern" style={{ bottom: "-160px", right: "-80px" }} />
          </Grid>
        </Grid>
      </ResponsiveContainer>
    </Box>
    )
  }

  return (
    
    <ResponsiveContainer includePadding>
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12} md={9.5} style={{ position: "relative" }}>
          <Box style={{ maxWidth: "720px" }}>
            <Typography variant="h1" mb={isSm ? 2 : 3}>We can help you find the right bank sponsor</Typography>
            <Typography variant="body1" mb={isSm ? 3 : 4}>Tell us a bit about yourself and your company, and we will help you find the right resource for your stage. That could be a direct introduction to one or more bank sponsors, or an introduction to a compliance consulting or advisory firm.</Typography>

            <Box className={classes.formGroup} display="flex" flexDirection="column" style={{ gap: isSm ? "16px" : "20px" }}>
              <Typography variant="h5">About you</Typography>
              <TextField
                fullWidth
                variant="filled"
                label="Full Name"
                value={formState.fullName || ''}
                onChange={e => setFormState(p => ({...p, fullName: e.target.value}))}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Role or title"
                value={formState.role || ''}
                onChange={e => setFormState(p => ({...p, role: e.target.value}))}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Personal email address"
                value={formState.email || ''}
                onChange={e => setFormState(p => ({...p, email: e.target.value}))}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Personal phone number"
                value={formState.phone || ''}
                onChange={e => setFormState(p => ({...p, phone: e.target.value}))}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Personal LinkedIn URL"
                value={formState.linkedin || ''}
                onChange={e => setFormState(p => ({...p, linkedin: e.target.value}))}
              />
            </Box>

            <Box className={classes.formGroup} display="flex" flexDirection="column" style={{ gap: isSm ? "16px" : "20px" }}>
              <Typography variant="h5">About your company</Typography>
              <TextField
                fullWidth
                variant="filled"
                label="Company name"
                value={formState.companyName || ''}
                onChange={e => setFormState(p => ({...p, companyName: e.target.value}))}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Company URL"
                value={formState.companyURL || ''}
                onChange={e => setFormState(p => ({...p, companyURL: e.target.value}))}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Company LinkedIn URL"
                value={formState.companyLinkedInURL || ''}
                onChange={e => setFormState(p => ({...p, companyLinkedInURL: e.target.value}))}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Company Crunchbase URL"
                value={formState.companyCrunchbaseURL || ''}
                onChange={e => setFormState(p => ({...p, companyCrunchbaseURL: e.target.value}))}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Country of incorporation (EIN)"
                value={formState.companyCountry || ''}
                onChange={e => setFormState(p => ({...p, companyCountry: e.target.value}))}
              />
              {formState.companyCountry === 'United States' && 
                <TextField
                  fullWidth
                  variant="filled"
                  label="State of incorporation (US only)"
                  value={formState.companyState || ''}
                  onChange={e => setFormState(p => ({...p, companyState: e.target.value}))}
                />
              }
              <TextField
                fullWidth
                variant="filled"
                label="How much capital have you raised?"
                value={formState.capitalRaised || ''}
                onChange={e => setFormState(p => ({...p, capitalRaised: e.target.value}))}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Who are your investors?"
                value={formState.investors || ''}
                onChange={e => setFormState(p => ({...p, investors: e.target.value}))}
              />
              <Box display="flex" flexDirection="column" style={{ gap: "8px" }}>
                <FormLabel component="legend" style={{ fontSize: "12px", lineHeight: "20px" }}>
                  Do you have an NMLS ID?
                </FormLabel>
                <Box display="flex" flexDirection="column" style={{ gap: "0" }}>
                  <FormControlLabel
                    label="No"
                    control={
                      <Radio
                        checked={formState.hasNMLS === false}
                        onChange={e => setFormState(p => ({...p, hasNMLS: !e.target.checked}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Yes"
                    control={
                      <Radio
                        checked={formState.hasNMLS === true}
                        onChange={e => setFormState(p => ({...p, hasNMLS: e.target.checked}))}
                      />
                    }
                  />
                </Box>
              </Box>
              {formState.hasNMLS === true && (
                <TextField
                  fullWidth
                  variant="filled"
                  label="What is your NMLS ID?"
                  value={formState.nmlsId || ''}
                  onChange={e => setFormState(p => ({...p, nmlsId: e.target.value}))}
                />
              )}
              <Box display="flex" flexDirection="column" style={{ gap: "8px" }}>
                <FormLabel component="legend" style={{ fontSize: "12px", lineHeight: "20px" }}>
                  Are you a Money Service Business (MSB)?
                </FormLabel>
                <Box display="flex" flexDirection="column" style={{ gap: "0" }}>
                  <FormControlLabel
                    label="No"
                    control={
                      <Radio
                        checked={formState.MSB === false}
                        onChange={e => setFormState(p => ({...p, MSB: !e.target.checked}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Yes"
                    control={
                      <Radio
                        checked={formState.MSB === true}
                        onChange={e => setFormState(p => ({...p, MSB: e.target.checked}))}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>

            <Box className={classes.formGroup} display="flex" flexDirection="column" style={{ gap: isSm ? "16px" : "20px" }}>
              <Typography variant="h5">About your product</Typography>
              <Box display="flex" flexDirection="column" style={{ gap: "8px" }}>
                <FormLabel component="legend" style={{ fontSize: "12px", lineHeight: "20px" }}>
                  Type of user?
                </FormLabel>
                <Box display="flex" flexDirection="column" style={{ gap: "0" }}>
                  <FormControlLabel
                    label="Business"
                    control={
                      <Radio
                        checked={formState.typeOfUser === 'Business'}
                        onChange={e => setFormState(p => ({...p, typeOfUser: 'Business'}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Consumer"
                    control={
                      <Radio
                        checked={formState.typeOfUser === 'Consumer'}
                        onChange={e => setFormState(p => ({...p, typeOfUser: 'Consumer'}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Both"
                    control={
                      <Radio
                        checked={formState.typeOfUser === 'Both'}
                        onChange={e => setFormState(p => ({...p, typeOfUser: 'Both'}))}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" style={{ gap: "8px" }}>
                <FormLabel component="legend" style={{ fontSize: "12px", lineHeight: "20px" }}>
                  Type of bank program?
                </FormLabel>
                <Box display="flex" flexWrap="wrap">
                  {bankProgramType.map(pt => (
                    <FormControlLabel
                      label={pt}
                      style={{ minWidth: "316px" }}
                      control={
                        <Checkbox checked={formState.bankProgramType.includes(pt)} onChange={e => {
                          const choices = formState.bankProgramType.filter(_pt => _pt !== pt)
                          if (e.target.checked) choices.push(pt)
                          setFormState({...formState, bankProgramType: choices})
                        }}/>
                      }
                    />
                  ))}
                </Box>
                {formState.bankProgramType && formState.bankProgramType.includes('Lending') && (
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Lending (please specify)"
                    value={formState.bankProgramTypeLending || ''}
                    onChange={e => setFormState(p => ({...p, bankProgramTypeLending: e.target.value}))}
                  />
                )}
                {formState.bankProgramType && formState.bankProgramType.includes('Other') && (
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Other (please specify)"
                    value={formState.bankProgramTypeOther || ''}
                    onChange={e => setFormState(p => ({...p, bankProgramTypeOther: e.target.value}))}
                  />
                )}
              </Box>
              <TextField
                  fullWidth
                  variant="filled"
                  label="Describe the product and the flow of funds"
                  value={formState.flowOfFunds || ''}
                  onChange={e => setFormState(p => ({...p, flowOfFunds: e.target.value}))}
                />
            </Box>

            <Box className={classes.formGroup} display="flex" flexDirection="column" style={{ gap: isSm ? "16px" : "20px" }}>
              <Typography variant="h5">About your compliance and information security programs</Typography>
              <Box display="flex" flexDirection="column" style={{ gap: "8px" }}>
                <FormLabel component="legend" style={{ fontSize: "12px", lineHeight: "20px" }}>
                  Do you have a compliance program?
                </FormLabel>
                <Box display="flex" flexDirection="column" style={{ gap: "0" }}>
                  <FormControlLabel
                    label="No"
                    control={
                      <Radio
                        checked={formState.complianceProgram === false}
                        onChange={e => setFormState(p => ({...p, complianceProgram: !e.target.checked}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Yes"
                    control={
                      <Radio
                        checked={formState.complianceProgram === true}
                        onChange={e => setFormState(p => ({...p, complianceProgram: e.target.checked}))}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" style={{ gap: "8px" }}>
                <FormLabel component="legend" style={{ fontSize: "12px", lineHeight: "20px" }}>
                  Do you have an experienced compliance officer?
                </FormLabel>
                <Box display="flex" flexDirection="column" style={{ gap: "0" }}>
                  <FormControlLabel
                    label="No"
                    control={
                      <Radio
                        checked={formState.complianceOfficer === 'No'}
                        onChange={e => setFormState(p => ({...p, complianceOfficer: 'No'}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Fractional"
                    control={
                      <Radio
                        checked={formState.complianceOfficer === 'Fractional'}
                        onChange={e => setFormState(p => ({...p, complianceOfficer: 'Fractional'}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Outsourced"
                    control={
                      <Radio
                        checked={formState.complianceOfficer === 'Outsourced'}
                        onChange={e => setFormState(p => ({...p, complianceOfficer: 'Outsourced'}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Part-time"
                    control={
                      <Radio
                        checked={formState.complianceOfficer === 'Part-time'}
                        onChange={e => setFormState(p => ({...p, complianceOfficer: 'Part-time'}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Full-time"
                    control={
                      <Radio
                        checked={formState.complianceOfficer === 'Full-time'}
                        onChange={e => setFormState(p => ({...p, complianceOfficer: 'Full-time'}))}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" style={{ gap: "8px" }}>
                <FormLabel component="legend" style={{ fontSize: "12px", lineHeight: "20px" }}>
                  Do you have a BSA / AML program?
                </FormLabel>
                <Box display="flex" flexDirection="column" style={{ gap: "0" }}>
                  <FormControlLabel
                    label="No"
                    control={
                      <Radio
                        checked={formState.bsaAML === false}
                        onChange={e => setFormState(p => ({...p, bsaAML: !e.target.checked}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Yes"
                    control={
                      <Radio
                        checked={formState.bsaAML === true}
                        onChange={e => setFormState(p => ({...p, bsaAML: e.target.checked}))}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" style={{ gap: "8px" }}>
                <FormLabel component="legend" style={{ fontSize: "12px", lineHeight: "20px" }}>
                  Do you have a Terms of Service?
                </FormLabel>
                <Box display="flex" flexDirection="column" style={{ gap: "0" }}>
                  <FormControlLabel
                    label="No"
                    control={
                      <Radio
                        checked={formState.terms === false}
                        onChange={e => setFormState(p => ({...p, terms: !e.target.checked}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Yes"
                    control={
                      <Radio
                        checked={formState.terms === true}
                        onChange={e => setFormState(p => ({...p, terms: e.target.checked}))}
                      />
                    }
                  />
                </Box>
                {formState.terms === true && (
                  <TextField
                    fullWidth
                    variant="filled"
                    label="What is your terms of services URL?"
                    value={formState.termsURL || ''}
                    onChange={e => setFormState(p => ({...p, termsURL: e.target.value}))}
                  />
                )}
              </Box>
              <Box display="flex" flexDirection="column" style={{ gap: "8px" }}>
                <FormLabel component="legend" style={{ fontSize: "12px", lineHeight: "20px" }}>
                  What security certifications and tests have you obtained?
                </FormLabel>
                <Box display="flex" flexWrap="wrap">
                  {certificates.map(pt => (
                    <FormControlLabel
                      label={pt}
                      style={{ minWidth: "100%" }}
                      control={
                        <Checkbox checked={formState.certificates.includes(pt)} onChange={e => {
                          const choices = formState.certificates.filter(_pt => _pt !== pt)
                          if (e.target.checked) choices.push(pt)
                          setFormState({...formState, certificates: choices})
                        }}/>
                      }
                    />
                  ))}
                </Box>
              </Box>
            </Box>

            <Box className={classes.formGroup} display="flex" flexDirection="column" style={{ gap: isSm ? "16px" : "20px" }}>
              <Typography variant="h5">Bank sponsorship</Typography>
              <Box display="flex" flexDirection="column" style={{ gap: "8px" }}>
                <FormLabel component="legend" style={{ fontSize: "12px", lineHeight: "20px" }}>
                  Do you have — or previously had — a bank sponsor at your current company?
                </FormLabel>
                <Box display="flex" flexDirection="column" style={{ gap: "0" }}>
                  <FormControlLabel
                    label="No"
                    control={
                      <Radio
                        checked={formState.bankSponsor === false}
                        onChange={e => setFormState(p => ({...p, bankSponsor: !e.target.checked}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Yes"
                    control={
                      <Radio
                        checked={formState.bankSponsor === true}
                        onChange={e => setFormState(p => ({...p, bankSponsor: e.target.checked}))}
                      />
                    }
                  />
                </Box>
                {formState.bankSponsor === true && (
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Who is/was your bank sponsor?"
                    value={formState.bankSponsorWho || ''}
                    onChange={e => setFormState(p => ({...p, bankSponsorWho: e.target.value}))}
                  />
                )}
              </Box>
              <Box display="flex" flexDirection="column" style={{ gap: "8px" }}>
                <FormLabel component="legend" style={{ fontSize: "12px", lineHeight: "20px" }}>
                  Have you already spoken with any middleware companies?
                </FormLabel>
                <Box display="flex" flexDirection="column" style={{ gap: "0" }}>
                  <FormControlLabel
                    label="No"
                    control={
                      <Radio
                        checked={formState.middleware === false}
                        onChange={e => setFormState(p => ({...p, middleware: !e.target.checked}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Yes"
                    control={
                      <Radio
                        checked={formState.middleware === true}
                        onChange={e => setFormState(p => ({...p, middleware: e.target.checked}))}
                      />
                    }
                  />
                </Box>
                {formState.middleware === true && (
                  <TextField
                    fullWidth
                    variant="filled"
                    label="What middleware companies have you spoken to?"
                    value={formState.middlewareWho || ''}
                    onChange={e => setFormState(p => ({...p, middlewareWho: e.target.value}))}
                  />
                )}
              </Box>
              <Box display="flex" flexDirection="column" style={{ gap: "8px" }}>
                <FormLabel component="legend" style={{ fontSize: "12px", lineHeight: "20px" }}>
                  Have you already spoken with any banks?
                </FormLabel>
                <Box display="flex" flexDirection="column" style={{ gap: "0" }}>
                  <FormControlLabel
                    label="No"
                    control={
                      <Radio
                        checked={formState.bankSpoken === false}
                        onChange={e => setFormState(p => ({...p, bankSpoken: !e.target.checked}))}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Yes"
                    control={
                      <Radio
                        checked={formState.bankSpoken === true}
                        onChange={e => setFormState(p => ({...p, bankSpoken: e.target.checked}))}
                      />
                    }
                  />
                </Box>
                {formState.bankSpoken === true && (
                  <TextField
                    fullWidth
                    variant="filled"
                    label="What banks have you spoken to?"
                    value={formState.bankSpokenWho || ''}
                    onChange={e => setFormState(p => ({...p, bankSpokenWho: e.target.value}))}
                  />
                )}
              </Box>
            </Box>

            <Box className={classes.formGroup} style={{ gap: isSm ? "16px" : "20px" }}>
              <TextField
                fullWidth
                variant="filled"
                label="Anything else you'd like to tell us..."
                value={formState.note || ''}
                onChange={e => setFormState(p => ({...p, note: e.target.value}))}
              />
            </Box>

            <FormControlLabel
              label="I consent to be contacted in response to my submission of this questionnaire"
              control={
                <Checkbox checked={consentToBeContacted} onChange={e => setConsentToBeContacted(e.target.checked)}/>
              }
            />
            <FormControlLabel
              label="I consent for my information to be shared with Embedded's partners (e.g. banks and compliance consulting firms) to help in assisting with my search for a bank sponsor."
              control={
                <Checkbox checked={consentToShareInfo} onChange={e => setConsentToShareInfo(e.target.checked)}/>
              }
              style={{ marginTop: "8px", marginBottom: "32px" }}
            />

            <LoadingButton variant="contained" disabled={(!(consentToBeContacted && consentToShareInfo))} onClick={submit} loading={loading}>Submit</LoadingButton>

          </Box>
          <img src="/images/pattern-questionnaire.svg" width="324" height="324" alt="Abstract pattern for decoration only" className="pattern" style={{ top: "-80px", right: "-120px", display: isSm ? "none" : undefined }} />
        </Grid>
      </Grid>
    </ResponsiveContainer>

  )
}