import {Box, Chip, Typography, Theme, useMediaQuery} from "@mui/material";
import {createUseStyles} from "react-jss";
import {generatePath, Link, useLocation, useParams} from "react-router-dom";
import {ROUTES} from "../app-config";
import {Program} from "../types";
import {formatDate} from "date-fns";
import {GenericSearchResult} from "./search-page";
import {useMemo} from "react";
import {buildExternalLink} from "../helpers";

const useStyles = createUseStyles({
  appIcon: {
    objectFit: "contain",
    height: "20px",
    width: "20px",
    marginRight: "8px"
  },
  centerLine: {
    display: "flex",
    alignItems: "center"
  },
  closedChip: {
    background: "var(--black)",
    color: "var(--white)"
  },
  creditIcon: {
    objectFit: "contain",
    width: "25px",
    height: "16px",
    marginLeft: "8px"
  },
  textLink: {
    fontSize: "12px", 
    lineHeight: "20px", 
    letterSpacing: "normal"
  },
  subContainer: {
    flexFlow: "row wrap"
  },
  subCard: {
    marginBottom: "0",
    alignItems: "center",
    padding: "12px",
    gap: "8px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: window.innerWidth <= 768 ? "100%" : "calc(33.3333% - 8px)",
  },
  phantomCard: {
    backgroundColor: "rgba(0, 0, 0, 0.01)",
    width: "100%",
    maxWidth: window.innerWidth <= 768 ? "100%" : "calc(33.3333% - 8px)",
    borderRadius: "8px"
  },
  subTruncate: {
    textOverflow: "ellipsis", 
    overflow: "hidden", 
    display: "-webkit-box", 
    WebkitLineClamp: "1", 
    WebkitBoxOrient: "vertical"
  }
})

interface Props {
  program: Program
}

export function ProgramCard({ program }: Props) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()
  const { slug: routeSlug} = useParams()
  const { pathname } = useLocation()

  const closed = program.closed

  const middlewares: GenericSearchResult[] = useMemo(() => {
    if (pathname.startsWith(ROUTES.MIDDLEWARE) && routeSlug !== undefined) {
      return program.middlewares.filter(pm => pm.slug !== routeSlug)
    }
    return program.middlewares
  }, [program, pathname, routeSlug])

  return (

    <Box className={closed ? "card-container programCardTop card-closed" : "card-container programCardTop"} key={program.slug} flexDirection={isSm ? 'column' : undefined} justifyContent={isSm ? undefined : 'space-between'} gap={isSm ? "16px" : "32px"} style={{ padding: isSm ? "20px" : "24px", alignItems: "start" }}>
      <Box display="flex" gap={isSm ? "8px" : "20px"} flexDirection={isSm ? 'column' : undefined} justifyContent={isSm ? undefined : 'space-between'} style={{ width: "100%" }}>
        <Link to={generatePath(ROUTES.COMPANY_DETAIL, {slug: program.company.slug})} style={{ display: "flex" }}><img src={program.company.logo || "/images/default-logo.png"} width={isSm ? "56px" : "72px"} height={isSm ? "56px" : "72px"} alt={program.company.name} style={{ borderRadius: "100%" }} /></Link>
        <Box style={{ width: "100%" }}>
          <Box className={classes.centerLine}>
            <Typography variant="h4">{program.name}</Typography>
            {program.networkType === "VISA" && (
              <img src="/images/credit-visa.svg" alt="Visa" className={classes.creditIcon}/>
            )}
            {program.networkType === "MASTERCARD" && (
              <img src="/images/credit-mastercard.svg" alt="Mastercard" className={classes.creditIcon}/>
            )}
            {program.networkType === "AMEX" && (
              <img src="/images/credit-amex.svg" alt="AMEX" className={classes.creditIcon}/>
            )}
            {program.networkType === "DISCOVER" && (
              <img src="/images/credit-discover.svg" alt="Discover" className={classes.creditIcon}/>
            )}
          </Box>
          <Typography variant="body2">{program.shortDescription || program.mediumDescription}</Typography>
          {closed && (
            <Box mt={1}>
              <Chip label={`Closed: ${formatDate(new Date(closed), "MMM yyyy")}`} classes={{ root: classes.closedChip }} />
            </Box>
          )}
          {(program.url || program.iosApp || program.googleApp || program.terms) && (
            <Box mt={1} display="flex" flexDirection={isSm ? 'column' : undefined} gap={isSm ? "12px" : "20px"} className="program-links">
              {program.url && (
                <Box className={classes.centerLine}>
                  <a href={buildExternalLink(program.url)} target="_blank" className={`text-link ${classes.textLink}`} rel="noreferrer">View Website</a>
                </Box>
              )}
              {program.iosApp && (
                <Box className={classes.centerLine}>
                  <img src="/images/icon-apple.svg" alt="IOS App" className={classes.appIcon}/>
                  <Link to={program.iosApp} target="_blank" className={`text-link ${classes.textLink}`}>iOS App</Link>
                </Box>
              )}
              {program.googleApp && (
                <Box className={classes.centerLine}>
                  <img src="/images/icon-google-play.svg" alt="Play Store App" className={classes.appIcon}/>
                  <Link to={program.googleApp} target="_blank" className={`text-link ${classes.textLink}`}>Google App</Link>
                </Box>
              )}
              {program.terms && (
                <Box className={classes.centerLine}>
                  <Link to={program.terms} target="_blank" className={`text-link ${classes.textLink}`}>Program Terms</Link>
                </Box>
              )}
            </Box>
          )}
          <Box mt={2.5} display="flex" gap="12px" className={classes.subContainer}>
            {/*{!(isBank && routeSlug === program.sponsor.slug) && (*/}
              <Link to={generatePath(ROUTES.BANK_DETAIL, {slug: program.sponsor.slug})} className={`card ${classes.subCard}`}>
                <img src={program.sponsor.logo || "/images/default-logo.png"} width="40" height="40" alt={program.sponsor.name} style={{ borderRadius: "100%" }} />
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold" className={classes.subTruncate}>{program.sponsor.name.replace(/,? (National Association|A National Banking Association)$/, "")}</Typography>
                  <Typography variant="subtitle1" className={classes.subTruncate}>Sponsor</Typography>
                </Box>
              </Link>
            {/*)}*/}
            {middlewares.map((m, i) => (
              <Link key={i} to={generatePath(ROUTES.MIDDLEWARE_DETAIL, {slug: m.slug})} className={`card ${classes.subCard}`}>
                <img src={m.logo || "/images/default-logo.png"} width="40" height="40" alt={m.name} style={{ borderRadius: "100%" }} />
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold" className={classes.subTruncate}>{m.name}</Typography>
                  <Typography variant="subtitle1" className={classes.subTruncate}>{m.category?.name || "Middleware"}</Typography>
                </Box>
              </Link>
            ))}
            {isSm ? undefined : Array.from({ length: middlewares.length < 3 ? 2 - middlewares.length : (middlewares.length < 6 ? 5 - middlewares.length : (middlewares.length < 9 ? 8 - middlewares.length : 0)) }).map((_, i) => (
              <div key={i} className={classes.phantomCard}></div>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>

  )
}
