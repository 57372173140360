import {useEffect, useRef, useState} from "react";
import {getEnv} from "./index";

import ReconnectingWebSocket from 'reconnecting-websocket';
import {stripEmptyKeys} from "./helpers";
const env = getEnv()

const primaryDomain = 'bankingembedded.com'

export interface PaginatedResults<T> {
    count: number;
    results: T[];
    next?: string;
    previous?: string;
}

export function apiRoot(): string {
    let apiRoot
    switch (env) {
        case "local":
            apiRoot = `${process.env.REACT_APP_WEBSERVER_HOST || "http://localhost:8001"}`
            // leave commented out or integration tests will fail
            // apiRoot = `https://dev-api.${primaryDomain}`
            break;

        case "prod":
            apiRoot = `https://api.${primaryDomain}`
            break;
        default:
            apiRoot = `https://${env}-api.${primaryDomain}`
    }

    return apiRoot;
}


export function openWebsocket({ url, getToken, reconnecting = true }: { url: string, getToken?: () => Promise<string>, reconnecting?: boolean }): ReconnectingWebSocket {
    const urlProvider = async () => {
        let token = ''
        if (getToken) {
            token = await getToken()
        }
        const websocketRoot =  apiRoot().replace(/^http/i, "ws");
        return `${websocketRoot}/${url}/?token=${token}`
    }

    return new ReconnectingWebSocket(urlProvider, undefined, { maxRetries: reconnecting ? undefined : 0, connectionTimeout: 10000 })
}


const apiDomain = `${apiRoot()}/api/v1`

export interface ApiCallInput {
    url: string,
    token?: string,
    params?: Record<string, any> | any[]
    method?: "get" | "post" | "put" | "delete" | "patch"
    responseHandler?: (response: Response) => void
    errorHandler?: (e: any) => void
    formData?: FormData
}

export function makeApiCall<T>(
    {
        url,
        token = '',
        params = {},
        method = "get",
        responseHandler,
        errorHandler,
        formData,
    }: ApiCallInput
): Promise<T> {
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    }

    if (formData) {
        // @ts-ignore
        delete headers["Content-Type"]
    }

    let requestParams = params
    if (!Array.isArray(params)) {
        requestParams = stripEmptyKeys(params)
    }

    let fullUrl = url[0] === "/" ? apiRoot() + url : `${apiDomain}/${url}/`
    if (Object.keys(requestParams).length > 0 && method === "get") {
        fullUrl += `?${new URLSearchParams(JSON.parse(JSON.stringify(requestParams))).toString()}`
    }

    return fetch(
        fullUrl,
        {
            headers,
            method,
            body: method === "get" ? undefined : (formData ? formData : JSON.stringify(requestParams))
        }
    ).then(r => {
        responseHandler && responseHandler(r)
        return r.json().catch(() => undefined)
    }).catch(reason => {
        reason && errorHandler && errorHandler(reason)
    })
}

export interface APIResponse<T> {
    data: T | undefined
    loading: boolean
    error: Response | null,
    refetch: () => void
}

export function useApi<T>(url: string, params: Record<string, string | undefined | number | boolean> = {}, skipErrorHandling?: boolean): APIResponse<T> {
    const [data, setData] = useState<T | undefined>(undefined);
    const [_refetch, setRefetch] = useState(0)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Response | null>(null);
    const paramString = JSON.stringify(params)
    const latestCallId = useRef(0)

    const statusChecker = (response: Response) => {
        if (response.status > 399 && !skipErrorHandling) {
            if (response.status === 403) {
                console.error('forbidden')
            } else {
                setError(new Response("unhandled error", {status: response.status}))
            }
        }
    }

    useEffect(() => {
        const callId = ++latestCallId.current

        setLoading(true)
        makeApiCall<T>({url, params: JSON.parse(paramString), method: "get", responseHandler: statusChecker}).then(data => {
            if (callId === latestCallId.current) {
                setData(data);
                setLoading(false);
            }
        }).catch(() => {
            if (callId === latestCallId.current && !skipErrorHandling) {
                setError(new Response("error", {status: 500}));
                setLoading(false);
            }
        })

    }, [url, paramString, _refetch])

    if (error) {
        throw error
    }

    return {data, loading, error, refetch: () => setRefetch(_refetch + 1)}
}
