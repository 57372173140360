import {Box} from "@mui/material";
import {generatePath, Link, useParams} from "react-router-dom";
import {PaginatedResults, useApi} from "../../api";
import {CompanyDetail} from "./types";
import {formatMoney} from "../../helpers";
import {PageLoader} from "../../components/page-loader";
import {ROUTES} from "../../app-config";
import {PageContent, PageHeader} from "../../components/page-sections";
import {EMPLOYEE_COUNTS} from "../../constants";
import {Program} from "../../types";

export function CompanyDetailPage() {
  const { slug } = useParams()
  const { data:  company, loading } = useApi<CompanyDetail>(`companies/${slug}`)
  const { data: programs } = useApi<PaginatedResults<Program>>(`programs`, { company: slug }, true)

  if (loading || !company) {
    return (
      <PageLoader />
    )
  }

  return (

    <Box>
      <PageHeader
        logo={company.logo}
        name={company.name}
        description={company.mediumDescription || company.shortDescription}
        entityType="Company"
        twitter={company.twitter}
        linkedIn={company.linkedin}
        crunchbase={company.crunchbase}
        url={company.url}
        closed={company.closed}
        intro={company.shortDescription || ''}
        dataPoints={[
          {title: "Type", value: company.category?.name === "Venture Capital" ? "Venture Capital" : `${company.ticker ? 'Public ' : 'Private'}${company.ticker && company.exchange ? `[${company.exchange}:${company.ticker}]` : ''}`},
          {title: "Parent Company", value: company.ownerCompany ? <Link to={generatePath(ROUTES.COMPANY_DETAIL, { slug: company.ownerCompany.slug})}>{company.ownerCompany.name}</Link> : undefined, hideEmpty: true},
          {title: "Parent FI", value: company.ownerFi ? <Link to={generatePath(ROUTES.BANK_DETAIL, { slug: company.ownerFi.slug})}>{company.ownerFi.name}</Link> : undefined, hideEmpty: true},
          {title: "Category", value: company.category?.name, hideEmpty: true},
          // @ts-ignore
          {title: "Employees", value: (EMPLOYEE_COUNTS[company.employeeCount!] || company.employeeCount), hideEmpty: true},
          {title: "Founded", value: company.founded, hideEmpty: true},
          {title: "Headquarters", value: company.address?.city ? `${company.address.city}${company.address.state ? `, ${company.address.state}` : ''}` : undefined, hideEmpty: true},
          {title: "Total Funding",  value: company.fundingAmount ? formatMoney(company.fundingAmount) : undefined, hideEmpty: true},
          {title: "State License Count", value: company.stateLicenseCount, hideEmpty: true},
          {title: "NMLS ID", value: company.nmlsId, hideEmpty: true},
          {title: "Emerging Markets", value: company.targetMarkets ? company.targetMarkets.map(tm => tm.name).join(', ') : undefined, hideEmpty: true}
        ]}
      />
      <PageContent 
        entity={company} 
        programs={programs} 
        programTitle="Programs embedding financial services" 
        subsidiaryTitle={`Subsidiaries of ${company.name}`}
      />
    </Box>

  )
}
