import {Typography, Button, Box, Grid, Theme, Chip, useMediaQuery} from "@mui/material";
import {ResponsiveContainer} from "../../components/responsive-container";
import {ArrowForward} from "@mui/icons-material";
import {useEffect} from "react";
import {setTitle} from "../../helpers";

export function InsightsArticleTwo() {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const articleTitle = "Why NASA thinks new BaaS banks have an advantage"

  useEffect(() => {
    setTitle(articleTitle)
    document.body.style.background = "url(/images/bg-white.jpg)"
    document.body.style.backgroundSize = "150px 150px"
    return () => {
      setTitle()
      document.body.style.background = "unset"
      document.body.style.backgroundSize = "unset"
    }
  })

  return (

    <ResponsiveContainer includePadding>
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12} md={9.5}>
          <Box style={{ maxWidth: "720px" }} className="insights-header">
            <Typography variant="subtitle1" mb={isSm ? 1 : 2}>March 4, 2024</Typography>
            <Typography variant="h1" mb={isSm ? 2.5 : 4}>{articleTitle}</Typography>
            <Box display="flex" gap="8px" style={{ flexWrap: "wrap", lineHeight: "20px" }} alignItems="center">
              <a href="/banks"><Chip label="Banks" /></a>
              <Chip label="Banking as a Service" />
            </Box>
          </Box>
          <Box mt={isSm ? 4 : 5} mb={isSm ? 4 : 7} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative" }}>
            <img src="/insights/nasa.jpg" width="720px" height="480px" alt="Abstract illustration related to banking and fintechs" style={{ width: "100%", maxWidth: "720px", height: "auto" }} />
            <Box style={{ display: isSm ? "none" : undefined, maxWidth: "160px" }}>
              <Typography variant="body2" mb={1.5}><a href="/insights/baas-bank-partner-landscape">BaaS bank partner landscape</a></Typography>
              <a href="/insights/baas-bank-partner-landscape">
                <Button variant='text' style={{ marginLeft: "-10px" }}>
                  <ArrowForward/>
                  Next Insight
                </Button>
              </a>
            </Box>
            <img src="/images/pattern-circle-1.svg" width="160" height="160" alt="Abstract pattern for decoration only" className="pattern" style={{ top: isSm ? "80px" : "245px", left: "-80px" }} />
          </Box>
          <Box className="article-body">
            <p>My grandmother – or maybe it was a college professor – frequently reminded me that the longer we wait to fix a problem, the more it costs. Leave it to the engineers at NASA to put this adage into a chart and calculate just how much more it costs based on how long we wait. Their 'Life-Cycle Cost Impacts from Early Phase Decision-Making' chart (shown below) suggests BaaS <a href="/banks">banks</a> battling regulatory enforcement face expenses 500-1000x more than a new entrant.</p>
            <h4>Life-cycle cost impacts from early phase decision-making</h4>
            <Box className="article-image">
              <img src="/insights/chart-nasa.png" width="720px" height="472px" alt="A chart displaying the life-cycle cost impacts from early phase decision-making" />
              <Typography variant="subtitle1" mt={2}>Source: <a href="https://www.nasa.gov/wp-content/uploads/2018/09/nasa_systems_engineering_handbook_0.pdf" target="blank" rel="noreferrer">NASA Systems Engineering Handbook</a></Typography>
            </Box>
            <h4>The BaaS bank translation</h4>
            <p>How do we get from their chart with NASA-specific review acronyms and life-cycle stages to saying incumbent BaaS banks have a real problem?</p>
            <p>First, take the “Concept” stage on the far left of the chart and consider this to be when a prudent BaaS bank is first considering the market opportunity. This is before they onboard a partner. Before they sign up with a <a href="/middleware">technology intermediary</a> (e.g. <a href="/middleware/unit-middleware">Unit</a>, <a href="/companies/q2">Q2 Helix</a>, <a href="/middleware/treasury-prime-middleware">Treasury Prime</a>, etc.).</p>
            <p>Second, take the “Operations through Disposal” stage on the far right of the chart and consider this to be a BaaS bank that has multiple BaaS partnerships and is in the middle of a second or third regulatory exam with significantly greater expectations.</p>
            <p>The blue diagonal arrow with numbers above it (e.g. 3-6x, 20-100x, 500-1000x) is NASA’s generalized estimate of the cost to change the design of any system. The bank’s system is a Risk and Compliance Management System. The chart suggests that existing banks need 500-1000x more investment to modify their existing risk and compliance program to meet the current expectations compared to the new BaaS bank that is just starting its investment.</p>
            <h4>Yeah, but 500-1000x more?</h4>
            <p>At first, this seemed a little exaggerated to me. Then, I read another of the seemingly weekly consent orders. The enforcement actions require the setup of a Board Committee ($), independent parties to review the program ($$), lookback reviews ($$$), “recommended” hiring ($$$$), and growth restrictions (in some cases, offboarding programs) even while increasing the costs to operate ($$$$$).</p>
            <p>An investment banker covering community banks shared their thesis with me that existing BaaS banks have an insurmountable advantage over new entrants. I’ve heard other bankers rationalize all the reasons why there won’t be many new BaaS banks.</p>
            <p>To me, 500-1000x doesn’t seem that far off. New BaaS banks have the benefit of learning from others’ previous mistakes, having a more complete picture of regulatory expectations, no dominant competitors, and they are starting with a clean slate (and not to mention all of this great information on <a href="/">bankingembedded.com</a>). Putting on my old strategy officer hat, being a new BaaS bank looks like a compelling position to be in if you’re willing to make the initial investment and weather the current storm.</p>
            <Box mt={5}>
              <Typography variant="h5" mb={0.5}>About the author</Typography>
              <Typography variant="body2">Matt West is the founder and CEO of <a href="https://workwithxenios.com" target="_blank" rel="noreferrer">Xenios</a>. As the former Chief Strategy Officer at <a href="/banks/mvb-bank">MVB Bank</a> (NASDAQ: MVBF), he was responsible for architecting and building their fintech business that grew from an idea to serving nearly a hundred fintech companies in five years.</Typography>
            </Box>
            <Box mt={4} display="flex" alignItems="center" gap="12px">
              <img src="/avatars/matt-west.jpg" width="56" height="56" alt="Matt West" style={{ borderRadius: "100%" }} />
              <Box>
                <Typography variant="h4" style={{ padding: "0" }}>Matt West</Typography>
                <Typography variant="subtitle1">CEO, Xenios</Typography>
              </Box>
              <a href="https://www.linkedin.com/in/matthewawest/" target="_blank" rel="noreferrer" className="icon-button icon-linkedin" style={{ marginLeft: "56px" }}>LinkedIn</a>
            </Box>
          </Box>
        </Grid>
        <img src="/images/pattern-triangle-1.svg" width="160" height="139" alt="Abstract pattern for decoration only" className="pattern" style={{ top: "500px", right: "-80px", display: isSm ? "none" : undefined }} />
      </Grid>
    </ResponsiveContainer>

  )
}
