import {Box, Theme, Typography, useMediaQuery} from "@mui/material";
import {Link} from "react-router-dom";
import {formatDate} from "date-fns";
import {NewsArticle} from "../types";

export function Insights({ news }: { news: NewsArticle[]}) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  if (news.length === 0) return null

  return (

    <Box mb={isSm ? 6 : 8} className="scrollTo" id="insights">
      <Typography variant="h3" mb={3}>Key insights</Typography>
      {news.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((n, i) => (
        <Box key={i} className="card-container" flexDirection={isSm ? 'column' : undefined} justifyContent={isSm ? undefined : 'start'} style={{ padding: isSm ? "20px" : "24px", gap: isSm ? "4px" : "16px" }}>
          <Box>
            <img src={n.publisher.logo || "/images/default-logo.png"} width="48px" height="48px" alt={n.publisher.name} style={{ borderRadius: "100%",  objectFit: "contain" }} />
          </Box>
          <Box>
            <Box mb={!n.title ? "0.5" : "0"} display="flex" gap="8px" alignItems="baseline">
              <Typography variant="h5">{n.title ? n.title : formatDate(new Date(n.date), 'MMM dd, yyyy')}</Typography>
              {!n.title && (
                <Link key={i} to={n.url} target="_blank" className="text-link">
                  <Typography variant="subtitle1">View Source</Typography>
                </Link>
              )}
            </Box>
            <Typography variant="body2">{n.description}</Typography>
            {n.title && (
              <Box mt={1} display="flex" gap="12px" alignItems="baseline">
                <Link key={i} to={n.url} target="_blank" className="text-link">
                  <Typography variant="subtitle1">View Source</Typography>
                </Link>
                <Typography variant="subtitle1">{formatDate(new Date(n.date), 'MMM dd, yyyy')}</Typography>
            </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>

  )
}
