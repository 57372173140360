import {generatePath, useParams, Link} from "react-router-dom";
import {PaginatedResults, useApi} from "../../api";
import {MiddlewareDetail} from "./types";
import {PageLoader} from "../../components/page-loader";
import {Box} from "@mui/material";
import {PageContent, PageHeader} from "../../components/page-sections";
import {EMPLOYEE_COUNTS} from "../../constants";
import {Program} from "../../types";
import {ROUTES} from "../../app-config";

export function MiddlewareDetailPage() {
  const { slug } = useParams()
  const { data:  middleware, loading } = useApi<MiddlewareDetail>(`middleware/${slug}`)
  const { data: programs } = useApi<PaginatedResults<Program>>(`programs`, { middleware: slug }, true)

  if (loading || !middleware) {
    return (
      <PageLoader />
    )
  }

  return (

    <Box>
      <PageHeader
        logo={middleware.logo}
        name={middleware.name}
        intro={middleware.shortDescription || ''}
        description={middleware.mediumDescription}
        twitter={middleware.company.twitter}
        linkedIn={middleware.company.linkedin}
        crunchbase={middleware.company.crunchbase}
        url={middleware.url || middleware.company.url}
        entityType="Middleware"
        closed={middleware.closed}
        dataPoints={[
          {title: "Type", value: `${middleware.company.ticker ? 'Public' : 'Private'} ${middleware.company.ticker && middleware.company.exchange ? `[${middleware.company.exchange}:${middleware.company.ticker}]` : ''}`},
          {title: "Category", value: middleware.category?.name, hideEmpty: true},
          {title: "Owner company", value: middleware.company.name ? <Link to={generatePath(ROUTES.COMPANY_DETAIL, { slug: middleware.company.slug})}>{middleware.company.name}</Link> : undefined, hideEmpty: true},
          // @ts-ignore
          {title: "Employees", value: (EMPLOYEE_COUNTS[middleware.company.employeeCount!] || middleware.company.employeeCount), hideEmpty: true},
          {title: "Founded", value: middleware.company.founded, hideEmpty: true},
          {title: "Headquarters", value: middleware.company.address?.city ? `${middleware.company.address.city}${middleware.company.address.state ? `, ${middleware.company.address.state}` : ''}` : undefined, hideEmpty: true}
        ]}
      />
      <PageContent entity={middleware} programs={programs} programTitle="Programs embedding financial services" />
    </Box>

  )
}
