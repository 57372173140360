import {Typography, Box} from "@mui/material";
import {useParams} from "react-router-dom";
import {ROUTES} from "../app-config";
import React from "react";
import {Link} from "react-router-dom";
import {useEffect} from "react";

export default function ({ error, clearError }: { error?: { status? : number } | undefined, clearError?: () => void }) {
  /*
  * "status" here seems like an http status, but isn't necessarily - 600 statuses are redirect calls to the /error/:status
  * url from the backend - a hack for making backend views not have to deal with error pages
  * */

  const status = Number(error?.status || useParams().status || 500)
  let mainText

  switch (status) {
    case 404:
      mainText = "Sorry, the page you're looking for has gone on a digital vacation to search for higher interest rates!"
      break
    case 403:
      mainText = "Sorry, accessing this page apparently requires a unique 18 character password, multi-factor authentication, a biometric scan, and knowledge of elliptic curve cryptography."
      break
    default:
      mainText = "Sorry, this page just received a cease and desist from its federal regulator."
  }

  console.error("error page shown for status", status)

  useEffect(() => {
    document.body.style.background = "url(/images/bg-white.jpg)"
    document.body.style.backgroundSize = "150px 150px"
    return () => {
      document.body.style.background = "unset"
      document.body.style.backgroundSize = "unset"
    }
  })

  return (

    <Box textAlign="center" style={{ position: "absolute", top: "50%", left: "50%", transform: "translateY(-50%) translateX(-50%)", maxWidth: "520px" }}>
      <img src="/images/illustration-error.svg" width="242" height="170" alt="Abstract illustration of an error status" />
      <Typography variant="body1" mt={2} mb={2}>{mainText}</Typography>
      <Typography variant="body2"><Link to={ROUTES.ROOT}>Try Going Here</Link></Typography>
    </Box>

  )
}
