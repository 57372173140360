import {Box, Tab, Tabs, Typography} from "@mui/material";
import {useMemo, useState} from "react";
import {ProgramCard} from "./program-card";
import {PaginatedResults} from "../api";
import {Program} from "../types";

export function Programs({ programs, title }: { programs: PaginatedResults<Program> | undefined, title: string }) {
  const [activeTab, setActiveTab] = useState(0)

  const programCategories = useMemo(() => {
    return Array.from(
      new Set(programs?.results
        .filter(p => Boolean(p.category))
        .sort((a, b) => (a.category?.priority || 100) - (b.category?.priority || 100))
        .map(p => p.category!.name)
      )
    )
  }, [programs?.results])

  if (!programs || programs.results.length === 0) return null

  const filteredPrograms = activeTab === 0 
    ? programs.results 
    : activeTab === programCategories.length + 1 
      ? programs.results.filter(p => p.closed) 
      : programs.results.filter(p => p.category?.name === programCategories[activeTab - 1])

  return (

    <Box className="scrollTo" id="programs">
      <Box>
        <Typography variant="h3" mb={1.5}>{title || "Programs"}</Typography>
        <Box mb={3}>
          <Tabs value={activeTab} onChange={(_, newVal) => setActiveTab(newVal)}>
            <Tab label={`All (${programs.results.length})`} />
            {programCategories.map((pType, i) => {
              const count = programs.results.filter(p => p.category?.name === pType && !p.closed).length;
              return <Tab key={i} label={`${pType} (${count})`} />;
            })}
            {programs.results.some(p => p.closed) && (
              <Tab label={`Closed (${programs.results.filter(p => p.closed).length})`} />
            )}
          </Tabs>
        </Box>
        {filteredPrograms
          .filter(p => activeTab === programCategories.length + 1 || !p.closed)
          .map(p => <ProgramCard key={p.slug} program={p} />)
        }
      </Box>
    </Box>

  )
}
