import {Box, Typography, Theme, useMediaQuery} from "@mui/material";
import {FIListed} from "../banks/types";
import {generatePath} from "react-router-dom";
import {ResultCard} from "../../components/result-card";
import {ROUTES} from "../../app-config";
import {CompanyListed} from "../company/types";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
  vcCards: {
    display: "flex",
    gap: "16px",
    flexWrap: "wrap",
    '& .card': {
      flexDirection: "column",
      width: "100%",
      maxWidth: window.innerWidth <= 768 ? "100%" : "calc(33.3333% - 12px)",
      margin: "0",
      padding: "24px",
      gap: "8px !important"
    },
    '& p': {
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      textOverflow: "ellipsis",
      WebkitLineClamp: "3"
    }
  },
  phantomCard: {
    backgroundColor: "rgba(0, 0, 0, 0.02)",
    width: "100%",
    maxWidth: window.innerWidth <= 768 ? "100%" : "calc(33.1111% - 8px)",
    borderRadius: "8px"
  }
})

export interface Portfolio {
  banks: FIListed[]
  companies: CompanyListed[]
}

export function PortfolioCompanies({ portfolio }: { portfolio: Portfolio}) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()

  return (

    <Box>
      
      <Box className="scrollTo" id="portfolio-companies" mb={isSm ? 6 : 8}>
        <Typography variant="h3" mb={3}>Portfolio</Typography>
        <Box className={classes.vcCards} style={{ flexDirection: isSm ? "column" : undefined }}>
          {portfolio.companies.map(c => (
            <ResultCard
              key={c.slug}
              linkTo={generatePath(ROUTES.COMPANY_DETAIL, {slug: c.slug})}
              title={c.name}
              text={c.shortDescription}
              logo={c.logo}
              linkChips={c.programSponsors.map(fi => ({
                name: fi.name,
                to: generatePath(ROUTES.BANK_DETAIL, {slug: fi.slug})
              }))}
              closed={c.closed}
              state={c.state}
            />
          ))}
          {portfolio.banks.map(c => (
            <ResultCard
              key={c.slug}
              linkTo={generatePath(ROUTES.BANK_DETAIL, {slug: c.slug})}
              title={c.name}
              text={c.shortDescription}
              logo={c.logo}
              closed={c.closed}
              state={c.state}
            />
          ))}
          {isSm ? undefined : Array.from({ length: (3 - portfolio.companies.length % 3) % 3 }).map((_, i) => (
            <div key={i} className={classes.phantomCard}></div>
          ))}
        </Box>
      </Box>

    </Box>

  )
}