import {Box, Theme, useMediaQuery} from "@mui/material";
import {useApi} from "../../api";
import {US_STATES} from "../../constants";
import {SlugItem} from "../../types";
import {CheckboxGroup} from "../../components/checkbox-group";
import {SearchInput} from "../../components/search-input";
import {CompanySearchProps} from "../company/search-filters";

const hideAfter = 5
const bottomMargin = 3

export function InvestorSearchFilters({ filter, onChange }: CompanySearchProps) {
  const { data: programCategories } = useApi<SlugItem[]>('programs/categories', undefined, true)

  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (

    <Box className="filter-fields">

      <SearchInput
        mb={isSm ? undefined : 4}
        value={filter.search || ''}
        onChange={search => onChange({...filter, search})}
      />

      <Box style={{ display: isSm ? "none" : undefined }}>

        <CheckboxGroup
          mb={bottomMargin}
          label="State"
          multiSelect
          value={filter.state || ''}
          items={US_STATES}
          onChange={(state) => onChange({...filter, state})}
          hideAfter={hideAfter}
        />

        <Box style={{ display: isSm ? "none" : undefined }}>
          <CheckboxGroup
            mb={bottomMargin}
            label="Program type"
            multiSelect
            items={programCategories}
            onChange={program_category => onChange({...filter, program_category })}
            value={filter.program_category}
            hideAfter={hideAfter}
          />
        </Box>

      </Box>

    </Box>

  )
}
