import {Box, Theme, Typography, useMediaQuery} from "@mui/material";
import {generatePath, Link} from "react-router-dom";
import {ROUTES} from "../app-config";
import {createUseStyles} from "react-jss";
import {AlpineCompany} from "../pages/company/types";

const useStyles = createUseStyles({
  subContainer: {
    flexFlow: "row wrap"
  },
  subCard: {
    padding: "12px 16px",
    gap: "12px",
    alignItems: "center",
    marginBottom: "0",
    width: "100%",
    maxWidth: window.innerWidth <= 768 ? "100%" : "calc(33.3333% - 8px)"
  },
  subName: {
    textOverflow: "ellipsis", 
    overflow: "hidden", 
    display: "-webkit-box", 
    WebkitLineClamp: "1", 
    WebkitBoxOrient: "vertical"
  },
  phantomCard: {
    backgroundColor: "rgba(0, 0, 0, 0.02)",
    width: "100%",
    maxWidth: window.innerWidth <= 768 ? "100%" : "calc(33.3333% - 8px)",
    borderRadius: "8px"
  }
})

export function SubsidiariesModule({subsidiaries,title}: { subsidiaries?: AlpineCompany[], title?: string }) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()

  if (!subsidiaries?.length) return null
  
  return (

    <Box id="subsidiaries" className="scrollTo" mb={isSm ? 6 : 8}>
      <Typography variant="h3" mb={3}>{title || "Subsidiaries"}</Typography>
      <Box display="flex" flexDirection={isSm ? "column" : undefined} gap="12px" className={classes.subContainer}>
        {subsidiaries.map((c, i) => (
          <Link to={generatePath(ROUTES.COMPANY_DETAIL, {slug: c.slug})} className={`card ${classes.subCard}`}>
          <img src={c.logo || "/images/default-logo.png"} width="32" height="32" alt={c.name} style={{ borderRadius: "100%" }} />
            <Typography variant="h5" className={classes.subName}>{c.name}</Typography>
          </Link>
        ))}
        {isSm ? undefined : Array.from({ length: (3 - subsidiaries.length % 3) % 3 }).map((_, i) => (
          <div key={`phantom${i}`} className={classes.phantomCard}></div>
        ))}
      </Box>
    </Box>
    
  )
}
