import {ReactNode, useEffect, useState} from "react";
import {Typography, Grid, CircularProgress, Button, Box, Theme, useMediaQuery} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import {LinkItem, SlugItem} from "../types";
import {setTitle} from "../helpers";
import {ResponsiveContainer} from "./responsive-container";
import {ResultCard} from "./result-card";
import {PaginatedResults, useApi} from "../api";

export interface GenericSearchResult extends SlugItem {
  shortDescription?: string
  logo?: string
  closed?: string
  state?: string
  category?: SlugItem
}

interface Props<T extends GenericSearchResult> {
  children: ReactNode
  pageTitle: string
  pageDescription: string
  logo: string
  logoAlt: string
  clearFilters: () => void
  buildLinkChips: (i: T) => LinkItem[]
  buildInfoChips?: (i: T) => string[]
  generatePath: (i: T) => string
  filter: object
  url: string
  state?: string
}

export const PAGE_LIMIT = 20

export function SearchPage<T extends GenericSearchResult>({ pageTitle, clearFilters, logo, logoAlt, pageDescription, children, buildLinkChips, buildInfoChips, filter, url, generatePath }: Props<T>) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [offset, setOffset] = useState(0)
  const [all, setAll] = useState<T[]>([])
  const { data, loading } = useApi<PaginatedResults<T>>(url, {...filter, limit: PAGE_LIMIT, offset })

  useEffect(() => {
    if (data?.results.length) {
      setAll(p => {
        const acc: T[] = []
        const slugs = p.map(r => r.slug)
        data.results.forEach(r => {
          if (!slugs.includes(r.slug)) {
            acc.push(r)
          }
        })

        return p.concat(acc)
      })
    }
  }, [data?.results])

  useEffect(() => {
    setAll([])
    setOffset(0)
  }, [filter]);

  useEffect(() => {
    setTitle(pageTitle)
    document.body.style.background = "url(/images/bg-alt.jpg)"
    document.body.style.backgroundSize = "150px 150px"

    return () => {
      setTitle()
      document.body.style.background = "unset"
      document.body.style.backgroundSize = "unset"
    }
  }, [pageTitle])

  return (

    <ResponsiveContainer includePadding>
      <Grid container mb={isSm ? 4 : 7}>
        <Grid item xs={12} md={3} mb={isSm ? "8px" : undefined}>
          <img src={logo} width="108" height="100" alt={logoAlt} style={{ width: isSm ? "auto" : undefined, maxHeight: isSm ? "80px" : undefined }} />
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="h1" mb={2}>{pageTitle}</Typography>
          <Typography variant="body1">
            {pageDescription}
          </Typography>
        </Grid>
      </Grid>

      <Grid container gap={isSm ? "24px" : undefined}>
        <Grid item xs={12} md={3}>
          {children}
        </Grid>
        <Grid item xs={12} md={9}>
          {!loading ? (
            <Box mb={isSm ? 1 : 2} display="flex" alignItems="center" justifyContent="space-between" style={{ height: "48px" }}>
              <Typography variant="body2">Displaying {data?.count || 0} results</Typography>
              <Button variant="text" onClick={clearFilters} style={{ fontWeight: "400", borderBottom: "1px solid #1C1A1F", borderRadius: "0", height: "auto", padding: "0", display: isSm ? "none" : undefined }}>Clear Filters</Button>
            </Box>
          ) : <CircularProgress /> }

          <InfiniteScroll
            next={() => setOffset(prev => prev + PAGE_LIMIT)}
            loader={<div />}
            hasMore={Boolean(data?.next)}
            dataLength={all.length}
            style={{ overflow: "visible" }}
          >
            {all.map(c => (
              <ResultCard
                key={c.slug}
                linkTo={generatePath(c)}
                title={c.name}
                text={c.shortDescription}
                logo={c.logo}
                linkChips={buildLinkChips(c)}
                infoChips={buildInfoChips && buildInfoChips(c)}
                closed={c.closed}
                state={c.state}
              />
            ))}
          </InfiniteScroll>
        </Grid>
      </Grid>
    </ResponsiveContainer>

  )
}
