import {useState} from "react";
import {CompanyFilter} from "../company/search-filters";
import {SearchPage} from "../../components/search-page";
import {CompanyListed} from "../company/types";
import {ROUTES} from "../../app-config";
import {generatePath} from "react-router-dom";
import {InvestorSearchFilters} from "./search-filters";

export function InvestorSearch() {
  const [filter, setFilter] = useState<CompanyFilter>({include_vcs: "true"})

  return (

    <SearchPage<CompanyListed>
      pageTitle="Investors"
      pageDescription="Below are venture capital funds that have invested in companies that have embedded financial services."
      logo="/images/illustration-investors.svg"
      logoAlt="Abstract illustration to represent fintech investors"
      generatePath={(i) => generatePath(ROUTES.INVESTOR_DETAIL, { slug: i.slug })}
      clearFilters={() => setFilter({})}
      buildLinkChips={c => []}
      url="companies"
      filter={filter}
    >
      <InvestorSearchFilters filter={filter} onChange={setFilter} />
    </SearchPage>
  )
}
