import {createTheme} from '@mui/material/styles';

export const HEADER_ZINDEX = 5000
export const MODAL_ZINDEX = 6000
export const NEG_ZINDEX = -1
export const ZINDEX_0 = 0
export const ZINDEX_1 = 1
export const ZINDEX_2 = 2


const baseTheme = createTheme({
  typography: {
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: "52px",
      lineHeight: "60px",
      letterSpacing: "-0.02em"
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: "37px",
      lineHeight: "48px",
      letterSpacing: "-0.02em"
    },
    h3: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: "22px",
      lineHeight: "32px",
      letterSpacing: "-0.02em"
    },
    h4: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "-0.02em"
    },
    h5: {
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px"
    },
    body1: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: "17px",
      lineHeight: "28px"
    },
    body2: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px"
    },
    subtitle1: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "20px"
    },
    subtitle2: {
      fontFamily: 'Roboto Mono',
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "20px",
      textTransform: "uppercase"
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: "var(--black)",
          color: "var(--white)",
          fontFamily: "'Inter'",
          fontWeight: "600",
          fontSize: "14px",
          letterSpacing: "normal",
          padding: "0 32px",
          height: "48px",
          textTransform: "capitalize",
          borderRadius: "48px",
          transition: "all .3s ease",
          boxShadow: "none",
          '&:hover': {
            padding: "0 36px",
            boxShadow: "none",
            background: "var(--black)",
            color: "var(--white)"
          },
          "& svg": {
            marginRight: "8px",
            maxHeight: "24px"
          }
        },
        outlined: {
          backgroundColor: "inherit",
        	color: "var(--black)",
        	padding: "0 28px",
        	border: "2px solid var(--black)",
          '&:hover': {
        		padding: "0 32px",
            backgroundColor: "inherit",
            color: "var(--black)",
            border: "2px solid var(--black)"
          }
        },
        text: {
          backgroundColor: "inherit",
          color: "var(--black)",
          padding: "0 10px",
          height: "32px",
          borderRadius: "32px",
          '&:hover': {
            backgroundColor: "rgba(28, 26, 31, .05)",
      			boxShadow: "0 0 0 8px rgba(28, 26, 31, .05)",
            padding: "0 10px",
            color: "var(--black)"
          }
        }
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          background: "var(--white)",
          border: "1px solid var(--box-border)",
          height: "24px",
          borderRadius: "24px",
          padding: "0 10px",
          fontFamily: 'Roboto Mono',
          fontSize: "10px",
          lineHeight: "20px",
          textTransform: "uppercase",
          letterSpacing: "normal",
          transition: "all .3s ease"
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "inherit",
          boxShadow: "0 -1px 0 0 rgba(28, 26, 31, .1) inset"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "20px",
          letterSpacing: "normal",
          textTransform: "capitalize",
          padding: "12px 16px",
          minWidth: "inherit",
          minHeight: "inherit",
          '&:hover': {
            backgroundColor: "rgba(28, 26, 31, .03)"
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "var(--black)",
          color: "var(--white)",
          padding: "20px",
          borderRadius: "8px",
          fontSize: "12px",
          lineHeight: "20px",
          fontWeight: "400"
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: "-6px"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontSize: "14px",
          lineHeight: "24px",
          letterSpacing: "normal",
          color: "var(--black)"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "48px",
          padding: "0",
          fontFamily: "'Inter'",
          fontWeight: "400",
          fontSize: "14px",
          letterSpacing: "normal",
          border: "none",
          outline: "none",
          background: "var(--white)",
          '&:hover': {
        		outline: "none"
          },
          '&:focus': {
        		outline: "none"
          },
          "& svg": {
            marginLeft: "16px"
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          padding: "0",
          fontFamily: "'Inter'",
          fontWeight: "400",
          fontSize: "14px",
          letterSpacing: "normal",
          border: "1px solid var(--box-border)",
          outline: "none",
          background: "var(--white)",
          '&:hover': {
        		outline: "none",
            background: "var(--white)",
            borderColor: "var(--white)"
          },
          '&:focus': {
        		outline: "none",
            background: "var(--white) !important"
          },
          "&:before": {
            border: "none",
            borderColor: "var(--white)"
          },
          "&:after": {
            borderColor: "var(--white)"
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "var(--box-border)",
          width: "32px",
          height: "32px",
          fontSize: "14px",
          lineHeight: "24px",
          letterSpacing: "normal"
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "var(--box-border)",
          width: "32px",
          height: "32px",
          fontSize: "14px",
          lineHeight: "24px",
          letterSpacing: "normal"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          margin: "24px 0 48px 0",
          boxShadow: "none",
          border: "1px solid var(--box-border)",
          borderRadius: "8px"
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          margin: "0",
          border: "none",
          borderRadius: "0"
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: "var(--light-blue)"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: "var(--box-border)",
          fontSize: "12px",
          lineHeight: "20px",
          "& a": {
            color: "var(--black)",
            borderBottom: "1px solid var(--box-border)",
            textDecoration: "none",
            transition: "all .3s ease"
          },
          "& a:hover": {
            borderColor: "var(--black)"
          }
        },
        head: {
          background: "var(--light-blue)"
        }
      }
    }
  },
  palette: {
    primary: {
      main: "#1C1A1F"
    }
  }
});

export const theme = createTheme(baseTheme, {
  typography: {
    h1: {
      ...baseTheme.typography.h1,
      [baseTheme.breakpoints.down('md')]: {
        fontSize: "37px",
        lineHeight: "44px",
      },
    },
    h2: {
      ...baseTheme.typography.h2,
      [baseTheme.breakpoints.down('md')]: {
        fontSize: "22px",
        lineHeight: "32px",
      },
    },
    h4: {
      ...baseTheme.typography.h4,
      [baseTheme.breakpoints.down('md')]: {
        fontSize: "15px",
        lineHeight: "24px",
      },
    },
    body1: {
      ...baseTheme.typography.body1,
      [baseTheme.breakpoints.down('md')]: {
        fontSize: "15px",
        lineHeight: "24px",
      },
    }
  },

})
