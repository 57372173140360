import {Box} from "@mui/material";

import {Hero} from "./hero";
import {LinkList} from "./link-list";
import {UseCases} from "./use-cases";
import {Insights} from "./insights";
import {Testimonials} from "./testimonials";

export function LandingPage() {

  document.body.style.background = "url(/images/bg-white.jpg)"
  document.body.style.backgroundSize = "150px 150px"

  return (

    <Box>

      <Hero />

      <LinkList />

      <UseCases />

      <Insights />

      <Testimonials />

    </Box>

  )
}
