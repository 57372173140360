import {SlugItem} from "./types";

export const CONTACT_EMAIL = "contact@bankingembedded.com"
export const GEOGRAPHIC_REGIONS: SlugItem[] = [
  { slug: "NORTH_AMERICA", name: "North America" },
  { slug: "EUROPE", name: "Europe" },
  { slug: "LATIN_AMERICA", name: "Latin America" },
  { slug: "ASIA", name: "Asia" },
  { slug: "MENA", name: "Middle East / North Africa" },
  { slug: "AFRICA", name: "Africa" },
];

export const EMPLOYEE_COUNTS = {
  ZERO_TO_10: "0 - 10",
  ELEVEN_TO_50: "11 - 50",
  FIFTY_ONE_TO_200: "51 - 200",
  TWO_HUNDRED_ONE_TO_500: "201 - 500",
  FIVE_HUNDRED_ONE_TO_1000: "501 - 1000",
  ONE_THOUSAND_ONE_TO_5000: "1001 - 5000",
  FIVE_THOUSAND_ONE_TO_10000: "5001 - 10,000",
  MORE_THAN_10000: "10,001+",
}

export const US_STATES: SlugItem[] = [
  { slug: "AL", name: "Alabama" },
  { slug: "AK", name: "Alaska" },
  { slug: "AZ", name: "Arizona" },
  { slug: "AR", name: "Arkansas" },
  { slug: "CA", name: "California" },
  { slug: "CO", name: "Colorado" },
  { slug: "CT", name: "Connecticut" },
  { slug: "DE", name: "Delaware" },
  { slug: "FL", name: "Florida" },
  { slug: "GA", name: "Georgia" },
  { slug: "HI", name: "Hawaii" },
  { slug: "ID", name: "Idaho" },
  { slug: "IL", name: "Illinois" },
  { slug: "IN", name: "Indiana" },
  { slug: "IA", name: "Iowa" },
  { slug: "KS", name: "Kansas" },
  { slug: "KY", name: "Kentucky" },
  { slug: "LA", name: "Louisiana" },
  { slug: "ME", name: "Maine" },
  { slug: "MD", name: "Maryland" },
  { slug: "MA", name: "Massachusetts" },
  { slug: "MI", name: "Michigan" },
  { slug: "MN", name: "Minnesota" },
  { slug: "MS", name: "Mississippi" },
  { slug: "MO", name: "Missouri" },
  { slug: "MT", name: "Montana" },
  { slug: "NE", name: "Nebraska" },
  { slug: "NV", name: "Nevada" },
  { slug: "NH", name: "New Hampshire" },
  { slug: "NJ", name: "New Jersey" },
  { slug: "NM", name: "New Mexico" },
  { slug: "NY", name: "New York" },
  { slug: "NC", name: "North Carolina" },
  { slug: "ND", name: "North Dakota" },
  { slug: "OH", name: "Ohio" },
  { slug: "OK", name: "Oklahoma" },
  { slug: "OR", name: "Oregon" },
  { slug: "PA", name: "Pennsylvania" },
  { slug: "RI", name: "Rhode Island" },
  { slug: "SC", name: "South Carolina" },
  { slug: "SD", name: "South Dakota" },
  { slug: "TN", name: "Tennessee" },
  { slug: "TX", name: "Texas" },
  { slug: "UT", name: "Utah" },
  { slug: "VT", name: "Vermont" },
  { slug: "VA", name: "Virginia" },
  { slug: "WA", name: "Washington" },
  { slug: "WV", name: "West Virginia" },
  { slug: "WI", name: "Wisconsin" },
  { slug: "WY", name: "Wyoming" }
]


export const NULL_VALUE = ' '
