import {Typography, Box, Grid, Theme, Chip, useMediaQuery} from "@mui/material";
import {ResponsiveContainer} from "../components/responsive-container";
import {useEffect} from "react";
import {setTitle} from "../helpers";

export function InsightsPage() {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  useEffect(() => {
    setTitle("Insights")
    document.body.style.background = "url(/images/bg-alt.jpg)"
    document.body.style.backgroundSize = "150px 150px"
    return () => {
      setTitle()
      document.body.style.background = "unset"
      document.body.style.backgroundSize = "unset"
    }
  })

  return (

    <Box>
      <ResponsiveContainer>
        <Grid container display="flex" justifyContent="center" mt={isSm ? 5 : 10} mb={isSm ? 5 : 7}>
          <Grid item xs={12} md={9.5} display="flex" justifyContent={isSm ? undefined : 'space-between'} flexDirection={isSm ? 'column-reverse' : undefined}>
            <Box style={{ maxWidth: "720px" }} mt={isSm ? 2 : undefined}>
              <Typography variant="h1" mb={2}>Insights</Typography>
              <Typography variant="body1">Data, research, and insights into the embedded finance landscape.</Typography>
            </Box>
            <img src="/images/illustration-insights.svg" width="104" height="100" alt="Illustration of a pen writing on a tablet" style={{ maxHeight: isSm ? "80px" : undefined }} />
          </Grid>
        </Grid>
        <Grid container display="flex" justifyContent="center" mb={10}>
          <Grid item xs={12} md={9.5} display="flex" flexDirection={isSm ? 'column' : undefined} justifyContent={isSm ? undefined : 'space-between'} style={{ flexFlow: "row wrap", gridColumnGap: isSm ? undefined : '3%', gridRowGap: isSm ? '20px' : '32px', position: "relative" }}>

            <a href="/insights/how-baas-banks-market-themselves" className="card card-insight" style={{ padding: isSm ? "20px" : "40px", margin: "0", flexBasis: isSm ? undefined : "48.5%" }}>
              <img src="/insights/thumb-baas-marketing.jpg" alt="Abstract illustration related to embedded banking" style={{ borderRadius: "100%", objectFit: "cover" }} />
              <Box>
                <Typography variant="subtitle1">Apr 8, 2024</Typography>
                <Typography variant="h2">How BaaS banks market themselves</Typography>
                <Box display="flex" gap="8px" style={{ flexWrap: "wrap", lineHeight: "20px" }} alignItems="center">
                  <Chip label="Baas" />
                  <Chip label="Marketing" />
                </Box>
              </Box>
            </a>

            <a href="/insights/baas-bank-partner-landscape" className="card card-insight" style={{ padding: isSm ? "20px" : "40px", margin: "0", flexBasis: isSm ? undefined : "48.5%" }}>
              <img src="/insights/thumb-baas.jpg" alt="Abstract illustration related to embedded finance" style={{ borderRadius: "100%", objectFit: "cover" }} />
              <Box>
                <Typography variant="subtitle1">Mar 4, 2024</Typography>
                <Typography variant="h2">BaaS partner bank landscape 2024</Typography>
                <Box display="flex" gap="8px" style={{ flexWrap: "wrap", lineHeight: "20px" }} alignItems="center">
                  <Chip label="Baas" />
                  <Chip label="Fintechs" />
                  <Chip label="Partner Banks" />
                </Box>
              </Box>
            </a>

            <a href="/insights/nasa-baas-banks" className="card card-insight" style={{ padding: isSm ? "20px" : "40px", margin: "0", flexBasis: isSm ? undefined : "48.5%" }}>
              <img src="/insights/nasa.jpg" alt="Abstract illustration related to embedded banking and finance" style={{ borderRadius: "100%", objectFit: "cover" }} />
              <Box>
                <Typography variant="subtitle1">Mar 4, 2024</Typography>
                <Typography variant="h2">Why NASA thinks new BaaS banks have an advantage</Typography>
                <Box display="flex" gap="8px" style={{ flexWrap: "wrap", lineHeight: "20px" }} alignItems="center">
                  <Chip label="Baas" />
                  <Chip label="Banks" />
                </Box>
              </Box>
            </a>

            <img src="/images/pattern-circle-3.svg" width="320" height="320" alt="Abstract pattern for decoration only" className="pattern" style={{ top: isSm ? "550px" : "260px", left: "-90px" }} />
            <img src="/images/pattern-triangle-1.svg" width="160" height="139" alt="Abstract pattern for decoration only" className="pattern" style={{ top: "250px", right: "-115px" }} />

          </Grid>
        </Grid>
      </ResponsiveContainer>
    </Box>

  )
}
