import {ReactElement} from "react";
import {Box, Button, Modal} from "@mui/material";
import {Close} from "@mui/icons-material";
import {createUseStyles} from "react-jss";

export const baseModalStyles = {
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    overflow:'scroll',
    height:'fit-content',
    maxHeight: "90%",
    margin: "auto",
    borderRadius: "16px",
    backgroundColor: "white",
    maxWidth: "90%",
    "&:focus": {
      outline: "none"
    },
    "&::-webkit-scrollbar": {
      display: "none"
    }
  }

const useStyles = createUseStyles({
  modal: baseModalStyles,
  modalContent: {
    padding: "60px 40px 40px 40px",
    "& .MuiFormControl-root": {
      marginBottom: "20px",
      width: "100%"
    },
    "& .MuiButton-outlined": {
      color: "var(--td-gray)",
      "&:hover": {
        color: "var(--td-white)"
      }
    },
  },
  closeIcon: {
    position: "absolute",
    top: "30px",
    right: "30px",
    cursor: "pointer"
  }
})

export function BeModal({ children, open, onClose }: { children: ReactElement, open: boolean, onClose: () => void }) {
  const classes = useStyles()
  return (
    <Modal style={{ top: "70px" }}  open={open} onClose={onClose}>
      <Box className={classes.modal} width={"560px"}>
          <Box className={classes.modalContent}>
                <Box className={classes.closeIcon}>
                    <Button onClick={onClose}>
                      <Close />
                    </Button>
                </Box>
            {children}
          </Box>
        </Box>
    </Modal>
  )
}