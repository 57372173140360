import {Box} from "@mui/material";
import {useParams, Link, generatePath} from "react-router-dom";
import {PaginatedResults, useApi} from "../../api";
import {PageLoader} from "../../components/page-loader";
import {PageContent, PageHeader} from "../../components/page-sections";
import {EMPLOYEE_COUNTS} from "../../constants";
import {CompanyDetail} from "../company/types";
import {Program} from "../../types";
import {Portfolio, PortfolioCompanies} from "./portfolio-section";
import {PageSection} from "../../components/sticky-nav";
import {ROUTES} from "../../app-config";

export function InvestorDetailPage() {
  const { slug } = useParams()
  const { data:  company, loading } = useApi<CompanyDetail>(`companies/${slug}`)
  const { data: programs } = useApi<PaginatedResults<Program>>(`programs`, { company: slug }, true)
  const { data: portfolio } = useApi<Portfolio>(`companies/${slug}/portfolio`)

  if (loading || !company) {
    return (
      <PageLoader />
    )
  }

  const additionalSections: PageSection[] = []

  if (portfolio && portfolio?.companies.length > 0) {
    additionalSections.push({title: "Portfolio", elementId: "portfolio-companies", section: <PortfolioCompanies portfolio={portfolio} />});
  }

  return (

    <Box>
      <PageHeader
        logo={company.logo}
        name={company.name}
        description={company.mediumDescription || company.shortDescription}
        entityType="Venture Capital"
        twitter={company.twitter}
        linkedIn={company.linkedin}
        crunchbase={company.crunchbase}
        url={company.url}
        closed={company.closed}
        intro={company.shortDescription || ''}
        dataPoints={[
          {title: "Type", value: company.category?.name, hideEmpty: true},
          {title: "Owner", value: company.ownerCompany ? <Link to={generatePath(ROUTES.COMPANY_DETAIL, { slug: company.ownerCompany.slug})}>{company.ownerCompany.name}</Link> : undefined, hideEmpty: true},
          {title: "Owner", value: company.ownerFi ? <Link to={generatePath(ROUTES.BANK_DETAIL, { slug: company.ownerFi.slug})}>{company.ownerFi.name}</Link> : undefined, hideEmpty: true},
          // @ts-ignore
          {title: "Team", value: (EMPLOYEE_COUNTS[company.employeeCount!] || company.employeeCount), hideEmpty: true},
          {title: "Founded", value: company.founded, hideEmpty: true},
          {title: "Headquarters", value: company.address?.city ? `${company.address.city}${company.address.state ? `, ${company.address.state}` : ''}` : undefined, hideEmpty: true}
        ]}
      />
      <PageContent
        entity={company}
        personsTitle="Investment team"
        programTitle="Limited partners"
        programs={programs}
        additionalSections={additionalSections}
      />
    </Box>

  )
}

