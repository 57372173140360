import './App.css';
import {ReactNode, useEffect, useState} from 'react';
import {Outlet, useLocation} from "react-router-dom";
import {Header} from "./components/navigation/header";
import {Footer} from "./components/navigation/footer";
import {Box, TextField} from "@mui/material";
import {ErrorBoundary} from "./error-boundary";
import {SitewideBanner} from "./components/banner";

function App() {

  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
    document.querySelector('head link[rel="canonical"]')?.setAttribute("href", window.location.href)
    document.querySelector('head meta[property="og:url"]')?.setAttribute("href", window.location.href)
  }, [pathname])

  return (

    <ErrorBoundary key={window.location.pathname}>
      <div className="App">
        {/*<SitewideBanner/>*/}
        <Header/>
        <Outlet/>
        <Footer/>
      </div>
    </ErrorBoundary>

  );
}

function LowAuth({children}: { children: ReactNode }) {
  const [pass, setPass] = useState('')

  const key = 'bankingEmbedded__logAuth'
  const authenticated = localStorage.getItem(key)

  if (authenticated) {
    return <div>{children}</div>
  }

  return (

    <Box height="100vh" width="100vw" className="absolute-center">
      <TextField
        label="Password"
        placeholder="Password"
        value={pass}
        onChange={e => {
          if (e.target.value === "bankingEmbedded") {
            localStorage.setItem(key, "true")
          }
          setPass(e.target.value)
        }}
      />
    </Box>

  )
}

export default App;
