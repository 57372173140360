import {UploadedDocument} from "../types";
import {Box, Theme, Typography, useMediaQuery} from "@mui/material";
import {createUseStyles} from "react-jss";
import {format} from "date-fns";
import {Link} from "react-router-dom";


const useStyles = createUseStyles({
  documentCard: {
    alignItems: "center", 
    gap: "16px"
  },
  thumbnail: {
    objectFit: "contain",
    height: "100%",
    maxHeight: "100%",
    maxWidth: "48px",
    border: "1px solid var(--box-border)"
  }
})

export function DocumentsSection({ documents }: { documents?: UploadedDocument[] }) {
  const classes = useStyles()
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  if (!documents || documents.length === 0) return null

  return (
    <Box id="documents" className="scrollTo"  mb={isSm ? 6 : 8}>
      <Typography variant="h3" mb={3}>Documents</Typography>
      {documents.map((d, i) => (
        <Box className={`card-container ${classes.documentCard}`} key={i} mb={i === documents.length - 1 ? 0 : 2} style={{ padding: isSm ? "20px" : "20px 24px" }}>
          <Link to={d.content} target="_blank">{d.thumbnail && <img src={d.thumbnail} className={classes.thumbnail} alt={d.title} />}</Link>
          <Box>
            <Box display="flex" alignItems="baseline">
              {d.publishedDate && <Typography variant="h5" mr={1}>{format(new Date(d.publishedDate), 'MMM dd, yyyy')}</Typography>}
              <Link to={d.content} target="_blank" className="text-link">
                <Typography variant="subtitle1">View PDF</Typography>
              </Link>
            </Box>
            <Typography variant="body2" mt={0.5}>{d.title}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  )
}
