import {Typography, Button, Box, Grid, Theme, Chip, useMediaQuery} from "@mui/material";
import {ResponsiveContainer} from "../../components/responsive-container";
import {ArrowForward} from "@mui/icons-material";
import {useEffect} from "react";
import {setTitle} from "../../helpers";

export function InsightsArticleOne() {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const articleTitle = "BaaS partner bank landscape 2024"

  useEffect(() => {
    setTitle(articleTitle)
    document.body.style.background = "url(/images/bg-white.jpg)"
    document.body.style.backgroundSize = "150px 150px"
    return () => {
      setTitle()
      document.body.style.background = "unset"
      document.body.style.backgroundSize = "unset"
    }
  })

  return (

    <ResponsiveContainer includePadding>
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12} md={9.5}>
          <Box style={{ maxWidth: "720px" }} className="insights-header">
            <Typography variant="subtitle1" mb={isSm ? 1 : 2}>March 4, 2024</Typography>
            <Typography variant="h1" mb={isSm ? 2.5 : 4}>{articleTitle}</Typography>
            <Box display="flex" gap="8px" style={{ flexWrap: "wrap", lineHeight: "20px" }} alignItems="center">
              <a href="/banks"><Chip label="Banks" /></a>
              <Chip label="Banking as a Service" />
              <a href="/companies"><Chip label="Fintechs" /></a>
            </Box>
          </Box>
          <Box mt={isSm ? 4 : 5} mb={isSm ? 4 : 7} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative" }}>
            <img src="/insights/embedded.jpg" width="720px" height="480px" alt="Visual of the Banking Embedded website" style={{ width: "100%", maxWidth: "720px", height: "auto" }} />
            <Box style={{ display: isSm ? "none" : undefined, maxWidth: "160px" }}>
              <Typography variant="body2" mb={1.5}><a href="/insights/how-baas-banks-market-themselves">How BaaS banks market themselves</a></Typography>
              <a href="/insights/how-baas-banks-market-themselves">
                <Button variant='text' style={{ marginLeft: "-10px" }}>
                  <ArrowForward/>
                  Next Insight
                </Button>
              </a>
            </Box>
            <img src="/images/pattern-circle-1.svg" width="160" height="160" alt="Abstract pattern for decoration only" className="pattern" style={{ top: isSm ? "80px" : "245px", left: "-80px" }} />
          </Box>
          <Box className="article-body">
            <Box style={{ padding: "24px 24px 0 24px", borderRadius: "16px", background: "url(/images/bg-alt.jpg) 150px 150px", marginBottom: "32px" }}>
              <Typography variant="body2"><em>Welcome to <a href="/">Banking Embedded</a>. Your go-to fintech intelligence resource. We map and track the fintech ecosystem in the US and internationally, keeping up with trends and aggregating the data in ways that make it easier to understand how the pieces of the fintech puzzle are put together.</em></Typography>
            </Box>
            <p>While we track many different types of bank partnerships — BaaS, Lending, Card Issuing, Merchant Acquiring, Cash Sweep Programs, Payments and more - today we are going deep on Banking as a Service (BaaS). Since the term is bandied about quite a bit - it is worth defining. Fortunately, Jason Henrichs and a group of 25 banks in the Alloy Labs Alliance (a team and organization I admire) have done the hard work here, and their definition in their report <a href="https://mcusercontent.com/f2abc2968daf71bdb44774fc4/files/f495d138-cfe6-ab78-1a9c-8af18d0dc98d/The_New_Nomenclature_Behind_the_BaaS_Partnership_Boom.pdf" target="_blank" rel="noreferrer">“The New Nomenclature Behind the BaaS Partnership Boom”</a> is succinct:</p>
            <blockquote>
              <p>BaaS: Acronym for banking-as-a-service. A partnership model in which a financial institution leverages its bank charter to enable one or more non-bank financial services companies to offer deposit accounts directly to consumers.</p>
            </blockquote>
            <p>I think this definition captures BaaS quite well. If I could quibble with any part of the definition it would be adding “financial services” after “non-bank” in the definition. Many of the companies embedding deposit accounts are in fact fintech or financial services companies (and maybe the very act of adding deposit accounts makes a non-fintech a fintech overnight). But, I would argue that many of the most interesting companies embedding deposit accounts are non-fintech and non-financial services brands (Is Amazon a fintech? How about Uber?) that have large and loyal client bases or key constituents (eg. Uber drivers) for which there is strong industrial logic for adding deposit accounts in order to best serve them or better meet their needs. But - fintech or not - the key to BaaS is the deposit account - which can only be offered by banks.</p>
            <p>Why start with BaaS? Well, in part because it is timely. Many of the banks who rushed into the space are now retreating under regulatory pressure, but under the hood the BaaS sponsorship market is maturing quite a bit, and some notable publicly traded banks are reaping huge rewards from focusing on BaaS, albeit not without lots of scrutiny, extra cost and likely non-public regulatory pressure.</p>
            <h4>BaaS overview</h4>
            <p>There are 60+ banks in our database that are in the BaaS sponsor market - meaning they have at least 1 non-bank partner that has embedded deposit accounts. Of those, 31 of them have 2 or more programs and the top 16 are presented here:</p>
            <Box className="article-image">
              <img src="/insights/chart-baas.png" width="720px" height="786px" alt="A chart highlighting top banking as a service banks" />
            </Box>
            <Typography variant="body2" mb={5}><em><sup>*</sup> This data is best thought of as directional. Not all companies that have embedded deposit account have disclosed their bank relationships, and in some cases (notably <a href="/banks/mvb-bank">MVB</a>) their partner is an MSB and does not need to disclose the relationship. Some of those relationships might be more of an FBO structure, which really isn’t the spirit of BaaS. As a result, for some banks I have confirmed it represents 90%+ of their BaaS relationships, for others it’s likely less than 20%.</em></Typography>
            <p>One thing that jumps out about this list is the importance of asset size. Unless you are concentrated in business account deposits - like <a href="/banks/webster-bank">Webster</a> - it pays to be under the $10b Durbin threshold. Interchange is still critical to the economics of BaaS programs. And while there are 60+ banks credibly in BaaS, many of these banks are relatively new entrants, have small BaaS deposit bases, and mostly use an indirect or quasi-indirect model through one or multiple of the middleware platforms <a href="/middleware/unit-middleware">Unit</a>, <a href="/middleware/synapse-middleware">Synapse</a>, <a href="/middleware/galileo-financial-technologies-middleware">Galileo</a>, <a href="/middleware/treasury-prime-middleware">Treasury Prime</a>, or <a href="/middleware/stripe-treasury-middleware">Stripe Treasury</a>. There are also notable BaaS banks who didn’t make the cutoff but have interesting BaaS businesses - <a href="/banks/third-coast-bank">Third Coast Bank</a>, <a href="/banks/lead-bank">Lead Bank</a> - or who have smaller but significantly larger BaaS customers - <a href="/banks/green-dot">Green Dot</a> - and will likely move up this list in the future.</p>
            <p>There are really ~15-20 mature franchises here and for them BaaS is the core of their present and in most cases their future. How can you tell? Well for one, they aren’t shy about BaaS. They frequently have dedicated BaaS brands - <a href="https://www.coastalbank.com/ccbx/" target="_blank" rel="noreferrer">CCBX</a>, <a href="https://www.lsbx.com/" target="_blank" rel="noreferrer">LSBX</a>, <a href="https://maast.com/" target="_blank" rel="noreferrer">Maast</a>, etc. [hold tight for a piece on how BaaS is marketed - short story: lots of room for improvement], they break it out in their financial reporting as a discreet business line, and perhaps most importantly they staff risk management in a serious way that regulators can get comfortable with.</p>
            <h4>BaaS risk management</h4>
            <p>Being in BaaS is not cheap. And to jump in and do risk management poorly risks your charter and/or your job. Look at the risk management staffing levels for two of the biggest players - <a href="/banks/mvb-bank">MVB</a> and <a href="/banks/coastal-community-bank">Coastal Community Bank</a> - each have well over 100 staff across risk management, BSA/ AML and compliance. These are banks that treat compliance seriously and are making the investments to keep their clients and their charters safe:</p>
            <Box className="article-image">
              <img src="/insights/chart-coastal-risk.png" width="720px" height="242px" alt="A chart showing Coastal risk management staffing" />
              <Typography variant="subtitle1" mt={2}>Source: Coast Financial Corporation Investor Presentation, January 30, 2024</Typography>
            </Box>
            <Box className="article-image">
              <img src="/insights/chart-mvb-risk.png" width="720px" height="241px" alt="A chart showing MVB risk management staffing" />
              <Typography variant="subtitle1" mt={2}>Source: MVB Investor Presentation, Q4 2023 “MVB F1: Success Loves Speed”</Typography>
            </Box>
            <p>Both <a href="/banks/coastal-community-bank">Coastal</a> and <a href="/banks/mvb-bank">MVB</a> have seen large increases in BSA, compliance and risk management staff over the past 3 years, rising from roughly 10% of their total headcount, to today representing 30% of headcount for both banks. This is a major shift in staffing, and is far from the norm at a typical community bank that to this day mostly accept local deposits:</p>
            <Box className="article-image">
              <img src="/insights/chart-mvb-coastal.png" width="720px" height="276px" alt="A chart showing risk staffing approach" />
            </Box>
            <p>This level of risk and compliance staffing is difficult to compete with and cannot be outsourced to a BaaS partner in an indirect relationship. For comparison, <a href="/banks/lineage-bank">Lineage Bank</a> has 64 full-time employees across the entire bank. <a href="/banks/thread-bank">Thread</a> has 53. <a href="/banks/piermont-bank">Piermont</a> has 49. <a href="/banks/lewis-and-clark-bank">Lewis &amp; Clark</a> 45. <strong><em>To put this in perspective, the combined risk management staff at just two large asset-size BaaS banks - <a href="/banks/coastal-community-bank">Coastal Community Bank</a> and <a href="/banks/mvb-bank">MVB</a> -is larger than the total headcount of four smaller asset-size BaaS banks - <a href="/banks/lineage-bank">Lineage</a>, <a href="/banks/thread-bank">Thread</a>, <a href="/banks/piermont-bank">Piermont</a>, and <a href="/banks/lewis-and-clark-bank">Lewis &amp; Clark</a> -combined.</em></strong> While simply looking at employee counts is a profound over-simplification, as these smaller BaaS banks mature their BaaS offerings and operations they will need to satisfy their regulators that they can keep pace with their compliance obligations, and if <a href="/banks/mvb-bank">MVB</a> and <a href="/banks/coastal-community-bank">Coastal</a> are a lesson, it won’t be cheap and will likely require massive increases in risk staffing. Oh and by the way, it’s not just people, <a href="/banks/coastal-community-bank">Coastal</a> and <a href="/banks/mvb-bank">MVB</a> have also invested heavily in process and technology, but that’s beyond the scope of this post.</p>
            <h4>BaaS regulatory headwinds</h4>
            <p>I won’t belabor this, because this issue is well covered by Jason Mikula over at <a href="https://fintechbusinessweekly.substack.com/" target="_blank" rel="noreferrer">Fintech Business Weekly</a>, but suffice it to say that if you are a bank operating in BaaS over the last few years you have felt the heat from regulators. A quick, non-comprehensive summary of recent regulatory actions:</p>
            <ul>
              <li><a href="/banks/lineage-bank">Lineage Bank</a> had its full board and management ousted and entered a consent order over its BaaS partnerships.</li>
              <li><a href="/banks/blue-ridge-bank">Blue Ridge Bank</a> is in a formal agreement with the OCC and is now considered in “troubled condition”.</li>
              <li><a href="/banks/green-dot">Green Dot Bank</a> revealed a proposed consent order from the Federal Reserve Board concerning compliance risk management, including consumer compliance and anti-money laundering regulations.</li>
              <li><a href="/banks/choice-bank">Choice Bank</a> entered a consent order with the FDIC related to its fintech business.</li>
              <li><a href="/banks/cross-river-bank">Cross River Bank</a> entered a consent order with the FDIC to resolve claims the bank engaged in unsafe or unsound fair lending compliance practices.</li>
            </ul>
            <p>These are just a sample of the public actions. Most center around risk management. For every public C&D or consent order I would fully expect that the other BaaS sponsor banks have also felt the uptick in pressure and urgency from the regulators seeking to effectively oversee fintech programs. Don’t expect this pressure to let up anytime soon. In BaaS for the time being, being bigger - and able to make bigger investments in risk infrastructure - is better, and likely should be an important factor for any fintech looking for a sponsor bank.</p>
            <h4>BaaS bull case</h4>
            <p>But, even given the cost of the risk and compliance overhead and the regulatory focus and headwinds, the benefits of BaaS can be profound:</p>
            <ul>
              <li>We estimate <a href="/banks/coastal-community-bank">Coastal Community Bank</a> will do ~$15-18m in fee income from their CCBX unit in 2024, and 56% of their deposit base - nearly $2b - comes from CCBX.</li>
              <li><a href="/banks/the-bancorp-bank">Bancorp Bank</a> already derives over 90% of its $6.4b in deposits from Fintech (though much of this is in Bancorp’s case is prepaid card issuance).</li>
              <li><a href="/banks/mvb-bank">MVB</a> derives 43% of its deposits from BaaS.</li>
              <li><a href="/banks/blue-ridge-bank">Blue Ridge</a> has been pruning its indirect BaaS business, but still has over $720m of fintech deposits which represents 26% of their deposit base (and while they are trimming BaaS they still intend to support their BaaS lending relationships).</li>
              <li><a href="/banks/green-dot">Green Dot</a> gets 52% of its revenue from its BaaS and paycard divisions (this in spite of losing several marquee BaaS customers).</li>
            </ul>
            <Box className="article-image">
              <img src="/insights/chart-deposits.png" width="720px" height="257px" alt="A chart showing bank and fintech deposits" />
              <Typography variant="subtitle1" mt={2}>Source: Public filings and investor presentations</Typography>
            </Box>
            <p>And the benefits can come relatively quickly. First Carolina Bank <a href="https://www.wilmingtonbiz.com/banking_and_finance/2023/12/08/fintech_partnership_nets_deposits_customers_for_first_carolina_bank/25101" target="_blank" rel="noreferrer">won the business</a> of college banking fintech BMTX and a $431m deposit pool (across 290k accounts) in December of 2023 on a 5 year contract, providing it a much-needed stable deposit base.</p>
            <p>And the growth was BIG last year. The business challenger bank landscape was fundamentally altered when SVB went under. <a href="/companies/mercury">Mercury</a> - the banking darling of Silicon Valley startups - added <a href="https://techcrunch.com/2023/07/07/mercury-says-it-gained-nearly-26k-new-customers-in-the-four-months-after-svbs-collapse/" target="_blank" rel="noreferrer">3,000 customers and $2b in deposits</a> in a single week after SVB’s collapse. They now claim 100,000 business customers, and are at a run rate of over $100b in annual transaction volume. It wouldn’t be entirely unsurprising if Mercury did $500m in revenue in 2024. That’s a real business and one that will drive a tremendous amount of deposits for Choice and Evolve, who are likely already facing a glut of fintech deposits. And Mercury isn’t alone in its success with businesses clients - <a href="/companies/bluevine">BlueVine</a>, <a href="/companies/rho">Rho</a>, <a href="/companies/northone">NorthOne</a> and dozen others have all grown briskly. And all-in they have only made a tiny dent in business deposits, with a lot of room to run.</p>
            <h4>The future of BaaS</h4>
            <p>I’ll leave my philosophical take on market structure and the future of BaaS for another day, but fortunately there are a couple of great recently published takes on BaaS from <a href="https://writing.kunle.app/p/fintech-can-save-the-american-banking" target="_blank" rel="noreferrer">Ayokunle Omojola</a> and <a href="https://workweek.com/2024/02/16/baas-follow-up/" target="_blank" rel="noreferrer">Alex Johnson</a>. While I am bullish on BaaS prospects, and concerned with the community bank impact. I think BaaS will be the single greatest disruptor of the “local deposits + local credit decisions” model of community banks, and unless we figure out a significant re-wiring, will only expedite the defining macro trend of community banks:</p>
            <Box className="article-image">
              <img src="/insights/chart-bank-branch.png" width="720px" height="424px" alt="A chart about community banks" />
            </Box>
            <h4>Inspiration and thanks</h4>
            <p>Finally, I can’t conclude without thanking a few folks who helped me in the effort to get <a href="/">Embedded</a> live or provided inspiration for the journey:</p>
            <ul>
              <li><a href="https://www.linkedin.com/in/matthewawest/" target="_blank" rel="noreferrer">Matt West</a> from <a href="https://workwithxenios.com" target="_blank" rel="noreferrer">Xenios</a> - a hands-on and frequent collaborator, who will also be writing pieces here.</li>
              <li>Lee Easton - <a href="https://www.thefintechmap.com/" target="_blank" rel="noreferrer">The Fintech Map</a> is a fantastic resource and does a great job visualizing the space.</li>
              <li><a href="https://workweek.com/brand/fintech-takes/" target="_blank" rel="noreferrer">Alex</a> and <a href="https://fintechbusinessweekly.substack.com/" target="_blank" rel="noreferrer">Jason</a> for their great research and writing that in part inspired me to start building Embedded.</li>
              <li>I still occasionally reference <a href="https://a16z.com/the-partner-bank-boom/" target="_blank" rel="noreferrer">this piece</a> from <a href="https://twitter.com/rexsalisbury" target="_blank" rel="noreferrer">Rex Salisbury</a> when he was still at a16z.</li>
              <li><a href="https://twitter.com/arfrank/status/1352372486793359360" target="_blank" rel="noreferrer">Aaron Frank’s tweet from 2021</a> is really the beginning of the story. He ultimately pulled together <a href="https://docs.google.com/spreadsheets/d/1r1WT0hbTlh3bswOPZk8ssKRjF9U33FVkpq74jN8cw4A/edit#gid=0" target="_blank" rel="noreferrer">an open source list</a>, and I like to think of <a href="/">Banking Embedded</a> as the living version of that list.</li>
            </ul>
            <Box mt={4} display="flex" alignItems="center" gap="12px">
              <img src="/avatars/ryan-alfred.jpg" width="56" height="56" alt="Ryan Alfred" style={{ borderRadius: "100%" }} />
              <Box>
                <Typography variant="h5" style={{ padding: "0" }}>Ryan Afred</Typography>
                <Typography variant="subtitle1">Founder, Embedded</Typography>
              </Box>
              <a href="https://www.linkedin.com/in/ryanalfred/" target="_blank" rel="noreferrer" className="icon-button icon-linkedin" style={{ marginLeft: "56px" }}>LinkedIn</a>
            </Box>
          </Box>
        </Grid>
        <img src="/images/pattern-triangle-1.svg" width="160" height="139" alt="Abstract pattern for decoration only" className="pattern" style={{ top: "500px", right: "-80px", display: isSm ? "none" : undefined }} />
      </Grid>
    </ResponsiveContainer>

  )
}
