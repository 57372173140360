import {Button, Checkbox, Radio, FormControlLabel, FormGroup, FormLabel, Box, Tooltip} from "@mui/material";
import {useState} from "react";
import {SlugItem} from "../types";
import {ExpandMore, Info} from "@mui/icons-material";
import {ExpandLess} from "@mui/icons-material";

interface Props {
  label: string
  items: SlugItem[] | undefined
  hideAfter?: number
  onChange: (s: string) => void
  value: string | undefined // comma separated
  mb?: number | string
  multiSelect?: boolean
  helpText?: string
}

export function CheckboxGroup({items, hideAfter, onChange, value = '', label, mb, multiSelect, helpText}: Props) {
  const [showMore, setShowMore] = useState(false)

  if (!items || items.length === 0) return null

  const hasMore = Boolean(hideAfter && items.length > hideAfter)
  const distinctValues = new Set(value.split(',').filter(v => v))

  return (

    <Box mb={mb}>
      <FormGroup>
      <FormLabel component="legend">
        <Box display="flex" alignItems="center">
          {label}
          {helpText && <Tooltip title={helpText}><Info style={{ marginLeft: "8px", fontSize: "18px" }} /></Tooltip>}
        </Box>
      </FormLabel>
      {items.slice(0, showMore ? undefined : hideAfter).map(item => (
        <FormControlLabel key={item.slug} control={
          multiSelect ? (
            <Checkbox checked={distinctValues.has(item.slug)} onChange={e => {
              if (e.target.checked) {
                distinctValues.add(item.slug);
              } else {
                distinctValues.delete(item.slug);
              }
              onChange(Array.from(distinctValues).join(','));
            }}/>
          ) : (
            <Radio checked={distinctValues.has(item.slug)} onChange={e => {
              onChange(e.target.checked ? item.slug : '');
            }}/>
          )
        }
        label={item.name} style={{ fontSize: '14px' }} />
      ))}
      {hasMore && (
        <Button onClick={() => setShowMore(prev => !prev)} style={{ fontWeight: "400", height: "auto", padding: "0", justifyContent: "start", marginTop: "8px", width: "fit-content" }}>
          {showMore ? <ExpandLess /> : <ExpandMore />}
          View {showMore ? "Less" : "More"}
        </Button>
      )}
    </FormGroup>
    </Box>

  )
}
