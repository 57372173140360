import {Box, Grid, Theme, Button, TextField, Typography, Tooltip, useMediaQuery} from "@mui/material";
import {LogoWithText} from "../icons";
import {ResponsiveContainer} from "../responsive-container";
import {CONTACT_EMAIL} from "../../constants";
import {Link} from "react-router-dom";
import {ROUTES} from "../../app-config";
import {useState} from "react";
import {validateEmail} from "../../helpers";
import {makeApiCall} from "../../api";
import {BeModal} from "../be-modal";

export function Footer() {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (

    <Box id="footer" style={{ background: "var(--black)", color: 'var(--white)' }}>
      <ResponsiveContainer includePadding>
        <Grid container display="flex" justifyContent="center">
          <Grid item xs={12} md={9.5}>
            <LogoWithText fill="var(--white)" />
            <Box mt={ isSm ? 2 : 4 } display="flex" justifyContent={isSm ? undefined : "space-between"} flexDirection={isSm ? "column" : undefined}>
              <Box maxWidth="440px">
                <Typography mb={3}>We want to use our knowledge and experience to make it easier for every company to build innovative financial products. If you believe we can help you, please do not hesitate to reach out via email, LinkedIn, or say hello on Twitter.</Typography>
                <Box display="flex" alignContent="center" gap="24px">
                  <Tooltip title={<><Typography variant="body2" fontWeight="700">Email:</Typography><Typography variant="body2">contact@bankingembedded.com</Typography></>} placement="top" followCursor PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [0, 10] } }] }}>
                    <a href={`mailto:${CONTACT_EMAIL}`}>
                      <Button variant="outlined" style={{ borderColor: "var(--white)", color: "var(--white)" }}>Contact Us</Button>
                    </a>
                  </Tooltip>
                  <a href="https://www.linkedin.com/company/banking-embedded/" target="_blank" rel="noreferrer" title="Follow us on LinkedIn" className="icon-button icon-linkedin dark-button">LinkedIn</a>
                  <a href="https://twitter.com/bankingembedded" target="_blank" rel="noreferrer" title="Follow us on X (Twitter)" className="icon-button icon-x dark-button">Twitter</a>
                </Box>
              </Box>
              <Box width="100%" maxWidth="320px" mt={ isSm ? 5 : 0 }>
                <Typography variant="body2" mb={2}>Subscribe below</Typography>
                <Subscribe />
              </Box>
            </Box>
            <Box mt={5} display="flex" width="100%" justifyContent={isSm ? undefined : "space-between"} flexDirection={isSm ? "column" : undefined}>
              <Typography variant="subtitle1">&copy; Get Embedded, LLC. 2024. All rights reserved.</Typography>
              <Box display="flex" mt={ isSm ? 2 : 0 }>
                <Typography variant="subtitle1" mr={3}>
                  <Link to={ROUTES.TERMS}>Terms of Service</Link>
                </Typography>
                <Typography variant="subtitle1">
                  <Link to={ROUTES.PRIVACY}>Privacy Policy</Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ResponsiveContainer>
    </Box>

  )
}

function Subscribe() {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  function doSubscribe() {
    if (validateEmail(email)) {
      setLoading(true)
      makeApiCall<any>({
        url: "subscribe",
        method: "post",
        params: { email },
        responseHandler: response => {
          if (response.status === 201) setSuccess(true)
        }
      }).catch(console.error).finally(() => setLoading(false))
    } else {
      setError(true)
    }
  }

  function doDismiss() {
    setEmail('')
    setSuccess(false)
    setError(false)
    setLoading(false)
  }

  return (

    <Box display="flex">
      <TextField
        placeholder="Enter your email address..."
        value={email}
        onChange={e => {
          setEmail(e.target.value)
          setError(false)
        }}
        error={error}
        helperText={error ? "Invalid email address" : undefined}
      />
      <Button
        onClick={doSubscribe}
        variant="contained"
        style={{ 
          borderRadius: "0 48px 48px 0", 
          background: "var(--yellow)", 
          color: "var(--black)",
          paddingLeft: "0",
          paddingRight: "0",
          minWidth: "100px"
        }}>
        {loading ? "..." : "Subscribe"}
      </Button>
      <BeModal open={success} onClose={doDismiss}>
        <Box>
          <Typography>Thank you for subscribing!</Typography>
        </Box>
      </BeModal>
    </Box>

  )
}
