import Container, {ContainerProps} from "@mui/material/Container";
import {Box, Breakpoint, Theme, useMediaQuery} from "@mui/material";

interface ResponsiveProps extends ContainerProps {
    allowFullWidth?: boolean
    includePadding?: boolean
}

export function ResponsiveContainer({ children, allowFullWidth, includePadding, ...props }: ResponsiveProps) {
    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    let maxWidth: Breakpoint = 'lg'
    if (isXs) maxWidth = 'xs'
    else if (isSm) maxWidth = 'sm'
    else if (isMd) maxWidth = 'md'
    else if (isLg) maxWidth = 'lg'

    if (isSm && allowFullWidth) {
        return <Box>{children}</Box>
    }

    return (
        <Container maxWidth={maxWidth} sx={{
            padding: { xs: includePadding ? "40px 0" : 0, md: includePadding ? "80px 0" : 0 },
            margin: {  xs: "auto" },
            maxWidth: {  xs: "calc(100% - 12%)", md: "1200px" },
            width: {  xs: "88%" },
        }}  {...props}>
            {children}
        </Container>
    )
}