import {Box, Grid, Theme, Typography, useMediaQuery} from "@mui/material";
import {createUseStyles} from "react-jss";
import {CheckOutlined} from "@mui/icons-material";

import {Specialty} from "../types";

const useStyles = createUseStyles({
  item: {
    display: "flex",
    alignItems: "start",
    marginBottom: "12px",
    "&:last-of-type": {
      marginBottom: 0
    }
  }
})

export function Specialties({ specialties }: { specialties: Specialty[] }) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()

  if (specialties.length === 0) return null

  const strengths = specialties.filter(s => s.attributeType === "STRENGTH")
  const weaknesses = specialties.filter(s => s.attributeType === "WEAKNESS")

  const sizes = { xs: 12, md: (strengths.length > 0 && weaknesses.length > 0) ? 6 : 12}

  return (

    <Grid className="scrollTo" id="specialties" container spacing={4} mb={isSm ? 6 : 8}>
      {strengths.length > 0 && (
        <Grid item {...sizes}>
          <Typography variant="h3" mb={3}>Best suited to</Typography>
          <Box className="card-container" style={{ padding: isSm ? "20px" : "24px", flexDirection: "column" }}>
            {strengths.map((s, i) => (
              <Box className={classes.item}>
                <CheckOutlined />
                <Typography variant="body2" ml={1}>{s.description}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      )}
      {weaknesses.length > 0 && (
        <Grid item {...sizes}>
          <Typography variant="h3" mb={3}>Supported use cases</Typography>
          <Box className="card-container" style={{ padding: isSm ? "20px" : "24px", flexDirection: "column" }}>
            {weaknesses.map((s, i) => (
              <Box className={classes.item}>
                <CheckOutlined />
                <Typography variant="body2" ml={1}>{s.description}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      )}
    </Grid>
    
  )
}
