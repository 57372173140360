import {Box, Theme, Typography, useMediaQuery} from "@mui/material";
import {generatePath, Link} from "react-router-dom";
import {ROUTES} from "../app-config";
import {createUseStyles} from "react-jss";
import {GenericSearchResult} from "./search-page";

const useStyles = createUseStyles({
  productCard: {
    width: "100%",
    marginBottom: "0",
    alignItems: "flex-start"
  }
})

export function ProductsModule({ middlewares, title }: { middlewares?: GenericSearchResult[], title?: string }) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()

  if (!middlewares?.length) return null
  
  return (

    <Box id="products" className="scrollTo" mb={isSm ? 6 : 8}>
      <Typography variant="h3" mb={3}>{title || "Embedded finance products"}</Typography>
      <Box display="flex" flexDirection="column" gap="12px">
        {middlewares.map((p, i) => (
          <Link key={i} to={generatePath(ROUTES.MIDDLEWARE_DETAIL, {slug: p.slug})} className={`card ${classes.productCard}`} style={{ padding: isSm ? "20px" : "24px", flexDirection: isSm ? 'column' : undefined, gap: isSm ? "8px" : "20px" }}>
            <img src={p.logo || "/images/default-logo.png"} width={isSm ? "56px" : "72px"} height={isSm ? "56px" : "72px"} alt={p.name} style={{ borderRadius: "100%" }} />
            <Box>
              <Typography variant="h4">{p.name}</Typography>
              <Typography variant="body2">{p.shortDescription}</Typography>
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
    
  )
}
