import {createBrowserRouter, RouteObject, RouterProvider} from "react-router-dom";
import App from "./App";
import React, {useEffect} from "react";
import {datadogLogs} from '@datadog/browser-logs'
import ErrorPage from "./pages/error-page";
import {getEnv} from "./index";
import {CompanySearch} from "./pages/company/search";
import {BankSearch} from "./pages/banks/search";
import {MiddlewareSearch} from "./pages/middleware/search";
import {LandingPage} from "./pages/landing/landing-page";
import {CompanyDetailPage} from "./pages/company/detail";
import {BankDetail} from "./pages/banks/detail";
import {MiddlewareDetailPage} from "./pages/middleware/detail";
import {TermsPage} from "./pages/terms";
import {PrivacyPage} from "./pages/privacy";
import {InsightsPage} from "./pages/insights";
import {InsightsArticleOne} from "./pages/insights/baas-bank-partner-landscape";
import {InsightsArticleTwo} from "./pages/insights/nasa-baas-banks";
import {InsightsArticleThree} from "./pages/insights/how-baas-banks-market-themselves";
import {InvestorSearch} from "./pages/investors/search";
import {InvestorDetailPage} from "./pages/investors/detail";
import {FEATURES_INVESTORS} from "./features";
import {FounderQuestionnaireForm} from "./pages/inquiry-form";

export const ROUTES = {
  ROOT: "/",
  BANKS: "/banks",
  BANK_DETAIL: "/banks/:slug",
  SPONSORS: "/sponsors",
  SPONSOR_DETAIL: "/sponsors/:sponsorType/:slug",
  COMPANIES: "/companies",
  COMPANY_DETAIL: "/companies/:slug",
  INVESTORS: "/investors",
  INVESTOR_DETAIL: "/investors/:slug",
  MIDDLEWARE: "/middleware",
  MIDDLEWARE_DETAIL: "/middleware/:slug",
  TERMS: "/terms",
  PRIVACY: "/privacy",
  INSIGHTS: "/insights",
  INSIGHTS_ARTICLE_ONE: "/insights/baas-bank-partner-landscape",
  INSIGHTS_ARTICLE_TWO: "/insights/nasa-baas-banks",
  INSIGHTS_ARTICLE_THREE: "/insights/how-baas-banks-market-themselves",
  FOUNDER_FORM: "/founder-form"
}

const appRoutes: RouteObject[] = [
  {
    path: ROUTES.ROOT,
    element: <LandingPage />
  },
  {
    path: ROUTES.COMPANIES,
    element: <CompanySearch />
  },
  {
    path: ROUTES.COMPANY_DETAIL,
    element: <CompanyDetailPage />
  },
  {
    path: ROUTES.BANKS,
    element: <BankSearch />
  },
  {
    path: ROUTES.BANK_DETAIL,
    element: <BankDetail />
  },
  {
    path: ROUTES.SPONSORS,
    element: <BankSearch />
  },
  {
    path: ROUTES.SPONSOR_DETAIL,
    element: <BankDetail />
  },
  {
    path: ROUTES.INVESTORS,
    element: <InvestorSearch />
  },
  {
    path: ROUTES.INVESTOR_DETAIL,
    element: FEATURES_INVESTORS ? <InvestorDetailPage /> : <CompanyDetailPage />
  },
  {
    path: ROUTES.MIDDLEWARE,
    element: <MiddlewareSearch />
  },
  {
    path: ROUTES.MIDDLEWARE_DETAIL,
    element: <MiddlewareDetailPage />
  },
  {
    path: ROUTES.TERMS,
    element: <TermsPage />
  },
  {
    path: ROUTES.PRIVACY,
    element: <PrivacyPage />
  },
  {
    path: ROUTES.INSIGHTS,
    element: <InsightsPage />
  },
  {
    path: ROUTES.INSIGHTS_ARTICLE_ONE,
    element: <InsightsArticleOne />
  },
  {
    path: ROUTES.INSIGHTS_ARTICLE_TWO,
    element: <InsightsArticleTwo />
  },
  {
    path: ROUTES.INSIGHTS_ARTICLE_THREE,
    element: <InsightsArticleThree />
  },
  {
    path: ROUTES.FOUNDER_FORM,
    element: <FounderQuestionnaireForm />
  }
]


const router = createBrowserRouter([
  {
    path: "/healthcheck",
    element: <pre>{JSON.stringify({ "status": "healthy", "env": getEnv() })}</pre>
  },
  {
    path: "*",
    element: <ErrorPage error={{status: 404}}/>
  },
  {
    path: ROUTES.ROOT,
    element: <App />,
    children: appRoutes
  }
])

export function Routes() {

  useEffect(() => {
    const env = getEnv()
    datadogLogs.init({
      clientToken: getEnv() === "local" ? '' : 'pub1f2f5075f6cf97c7a445b6377c3740ff',
      site: 'us5.datadoghq.com',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: "all",
      sessionSampleRate: 100,
      env,
      service: "website"
    })
  }, [])

  return (
    <RouterProvider router={router}/>
  )

}
