import {Box} from "@mui/material";
import {useParams} from "react-router-dom";
import {PaginatedResults, useApi} from "../../api";
import {FIDetail} from "./types";
import {formatMoney} from "../../helpers";
import {PageLoader} from "../../components/page-loader";
import {PageContent, PageHeader} from "../../components/page-sections";
import {Program} from "../../types";

export function BankDetail() {
  const { slug } = useParams()
  const { data:  fi, loading } = useApi<FIDetail>(`financial-institutions/${slug}`)
  const { data: programs } = useApi<PaginatedResults<Program>>(`programs`, { sponsor: slug, limit: 200 }, true)

  if (loading || !fi) {
    return (
      <PageLoader />
    )
  }

  return (

    <Box>
      <PageHeader
        logo={fi.logo}
        name={fi.name}
        intro={fi.shortDescription || ''}
        description={fi.longDescription}
        twitter={fi.twitterUrl}
        linkedIn={fi.linkedInUrl}
        crunchbase={fi.crunchbaseUrl}
        url={fi.url}
        closed={fi.closed}
        entityType="Bank"
        dataPoints={[
          {title: "Asset size", value: formatMoney(fi.totalAssets, 1), hideEmpty: true},
          {title: "Member count", value: fi.memberCount?.toLocaleString(), hideEmpty: true},
          {title: "Durbin-capped", value: fi.totalAssets ? (fi.totalAssets > 10000000000 ? "Yes" : "No") : undefined, hideEmpty: true},
          {title: "FDIC cert", value: fi.fdicCertNumber, hideEmpty: true},
          {title: "Primary federal regulator", value: fi.regulator, hideEmpty: true},
          {title: "Type", value: fi.ticker ? "Public" : "Private"},
          {title: "Employees", value: fi.employees?.toLocaleString(), hideEmpty: true},
          {title: "Founded", value: fi.founded, hideEmpty: true},
          {title: "Headquarters", value: fi.address?.city ? `${fi.address.city}${fi.address.state ? `, ${fi.address.state}` : ''}` : undefined, hideEmpty: true}
        ]}
      />
      <PageContent 
        entity={fi} 
        programs={programs} 
        programTitle="Partner programs" 
        subsidiaryTitle={`Subsidiaries of ${fi.name}`}
      />
    </Box>

  )
}
