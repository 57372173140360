import React, {Component} from "react";
import ErrorPage from "./pages/error-page";


export class ErrorBoundary extends Component {
    state = {hasError: false, error: undefined}

    constructor(props) {
        super(props);
        this.state = { hasError: false, error: undefined };
    }


    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }

    componentDidCatch(error, info) {
       console.error('error boundary triggered', error, info);
    }

    clearError() {
        this.setState({ error: undefined, hasError: false })
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorPage clearError={this.clearError} error={this.state.error} />
        }

        return this.props.children;
    }
}