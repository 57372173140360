import {Box, Grid, Theme, Typography, useMediaQuery} from "@mui/material";
import {ResponsiveContainer} from "../../components/responsive-container";

export function UseCases() {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (

    <ResponsiveContainer includePadding style={{ paddingTop: isSm ? "56px" : "80px" }}>
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12} md={9.5}>
          <Typography variant="h2" mb={isSm ? 4 : 6}>Embedded helps:</Typography>
          <Box display="flex" flexDirection={isSm ? 'column' : undefined} justifyContent={isSm ? undefined : 'space-between'} style={{ flexFlow: "row wrap", gridColumnGap: isSm ? undefined : '8%', gridRowGap: isSm ? '24px' : '32px' }}>
            <Box display="flex" gap={isSm ? "20px" : "24px"} style={{ flexBasis: isSm ? undefined : '46%' }}>
              <img src="/images/illustration-bankers.svg" width="60" height="60" alt="Abstract illustration to represent people working in banking" />
              <Box>
                <Typography variant="h4" mb={0.5}>Banks</Typography>
                <Typography variant="body2">Identify trends in the services companies are embedding, which banks and middleware companies are scaling their embedded businesses, and build direct relationships with the founders and teams actively looking for bank sponsors.</Typography>
              </Box>
            </Box>
            <Box display="flex" gap={isSm ? "20px" : "24px"} style={{ flexBasis: isSm ? undefined : '46%' }}>
              <img src="/images/illustration-investors.svg" width="60" height="60" alt="Abstract illustration to represent fintech investors" />
              <Box>
                <Typography variant="h4" mb={0.5}><a href="/investors">Investors</a></Typography>
                <Typography variant="body2">Let us help your portfolio companies scale their embedded finance businesses, through data, insights, advice, and connectivity to our network.</Typography>
              </Box>
            </Box>
            <Box display="flex" gap={isSm ? "20px" : "24px"} style={{ flexBasis: isSm ? undefined : '46%', position: "relative" }}>
              <img src="/images/illustration-founders.svg" width="60" height="76" alt="Abstract illustration to represent fintech founders" />
              <Box>
                <Typography variant="h4" mb={0.5}>Founders</Typography>
                <Typography variant="body2">Gain a better understanding of the banks and middleware partners that are the best fit for what you are looking to build, and connect with the right people to help you get your products live.</Typography>
              </Box>
              <img src="/images/pattern-circle-2.svg" width="160" height="160" alt="Abstract pattern for decoration only" className="pattern" style={{ top: "-53px", left: "-55px" }} />
            </Box>
            <Box display="flex" gap={isSm ? "20px" : "24px"} style={{ flexBasis: isSm ? undefined : '46%' }}>
              <img src="/images/illustration-journalists.svg" width="60" height="76" alt="Abstract illustration to represent journalists" />
              <Box>
                <Typography variant="h4" mb={0.5}>Journalists</Typography>
                <Typography variant="body2">It’s an exciting time in embedded banking. <a href="#footer">Subscribe</a> for timely updates to our data as we announce new partnerships, major regulatory news, and significant trends in embedded banking.</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ResponsiveContainer>

  )
}
