import {Box, InputAdornment, TextField, Theme, useMediaQuery} from "@mui/material";
import {Search} from "@mui/icons-material";

export function SearchInput({ value, onChange, mb }: { value: string, onChange: (s: string) => void, mb?: number}) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (

    <Box mb={mb} style={{ width: isSm ? "100%" : "80%" }} className="search-field">
      <TextField
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder="Search for..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </Box>

  )
}
