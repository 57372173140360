import {Box, Button, Grid, Theme, Typography, useMediaQuery} from "@mui/material";
import {Link} from "react-router-dom";
import {ROUTES} from "../../app-config";
import {ArrowForward} from "@mui/icons-material";
import {ResponsiveContainer} from "../../components/responsive-container";

export function LinkList() {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (

    <ResponsiveContainer>
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12} md={9.5} display="flex" flexDirection={isSm ? 'column' : undefined} justifyContent={isSm ? undefined : 'space-between'} gap={isSm ? "24px" : "32px"}>
          <Box display="flex" flexGrow="1" flexBasis="0" style={{ position: "relative" }}>
            <Link to={ROUTES.BANKS} className="card" style={{ textAlign: "center", padding: isSm ? "32px 16px" : "40px 24px", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              <img src="/images/illustration-banks.svg" width="88" height="100" alt="Abstract illustration to represent banks" style={{ width: "100%", height: "auto", maxHeight: isSm ? "72px" : "100px" }} />
              <Typography variant="h3" mt={isSm ? 1.5 : 2} mb={0.5}>Sponsors</Typography>
              <Typography variant="body2" mb={isSm ? 1.5 : 2}>Find partner banks based on their expertise, areas of focus, and current embedded banking partners</Typography>
              <Button variant="contained">
                <ArrowForward />
                Sponsors
              </Button>
            </Link>
            <img src="/images/pattern-circle-1.svg" width="160" height="160" alt="Abstract pattern for decoration only" className="pattern" style={{ top: "128px", left: "-100px" }} />
          </Box>
          <Box display="flex" flexGrow="1" flexBasis="0" style={{ position: "relative" }}>
            <Link to={ROUTES.COMPANIES} className="card" style={{ textAlign: "center", padding: isSm ? "32px 16px" : "40px 24px", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              <img src="/images/illustration-companies.svg" width="108" height="100" alt="Abstract illustration to represent fintech companies" style={{ width: "100%", height: "auto", maxHeight: isSm ? "72px" : "100px" }} />
              <Typography variant="h3" mt={isSm ? 1.5 : 2} mb={0.5}>Companies</Typography>
              <Typography variant="body2" mb={isSm ? 1.5 : 2}>Find software companies that have embedded finance products, and learn about their programs</Typography>
              <Button variant="contained">
                <ArrowForward />
                Companies
              </Button>
            </Link>
            <img src="/images/pattern-triangle-1.svg" width="160" height="139" alt="Abstract pattern for decoration only" className="pattern" style={{ bottom: "-80px", right: isSm ? "-36px" : "-76px" }} />
          </Box>
          <Box display="flex" flexGrow="1" flexBasis="0">
            <Link to={ROUTES.INVESTORS} className="card" style={{ textAlign: "center", padding: isSm ? "32px 16px" : "40px 24px", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              <img src="/images/illustration-investors.svg" width="84" height="100" alt="Abstract illustration to represent fintech venture capital funds" style={{ width: "100%", height: "auto", maxHeight: isSm ? "72px" : "100px" }} />
              <Typography variant="h3" mt={isSm ? 1.5 : 2} mb={0.5}>Investors</Typography>
              <Typography variant="body2" mb={isSm ? 1.5 : 2}>Find venture capital funds that have invested in companies that have embedded financial services.</Typography>
              <Button variant="contained">
                <ArrowForward />
                Investors
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </ResponsiveContainer>

  )
}
