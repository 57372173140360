import {Box, Grid, Theme, Typography, Chip, useMediaQuery} from "@mui/material";
import {ResponsiveContainer} from "../../components/responsive-container";

export function Insights() {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (

    <ResponsiveContainer style={{ paddingTop: isSm ? "16px" : "0", paddingBottom: isSm ? "32px" : "80px", position: "relative" }}>
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12} md={9.5}>
          <Typography variant="h3" mb={3}>Embedded banking insights:</Typography>
          <Box display="flex" flexDirection={isSm ? 'column' : undefined} justifyContent={isSm ? undefined : 'space-between'} style={{ flexFlow: "row wrap", gridColumnGap: isSm ? undefined : '3%', gridRowGap: isSm ? '20px' : '32px', position: "relative" }}>

            <a href="/insights/how-baas-banks-market-themselves" className="card" style={{ padding: isSm ? "20px" : "32px", gap: isSm ? "16px" : "24px", margin: "0", flexBasis: isSm ? undefined : "48.5%" }}>
              <img src="/insights/thumb-baas-marketing.jpg" alt="Abstract illustration related to banking as a service marketing" style={{ width: isSm ? "80px" : "120px", height: isSm ? "80px" : "120px", borderRadius: "100%", objectFit: "cover" }} />
              <Box>
                <Typography variant="subtitle1">Apr 8, 2024</Typography>
                <Typography variant="h3" mt={0.5}>How BaaS banks market themselves</Typography>
                <Box mt={1.5} display="flex" gap="8px" style={{ flexWrap: "wrap", lineHeight: "20px", display: isSm ? "none" : "flex" }} alignItems="center">
                  <Chip label="Baas" />
                  <Chip label="Marketing" />
                </Box>
              </Box>
            </a>

            <a href="/insights/baas-bank-partner-landscape" className="card" style={{ padding: isSm ? "20px" : "32px", gap: isSm ? "16px" : "24px", margin: "0", flexBasis: isSm ? undefined : "48.5%" }}>
              <img src="/insights/thumb-baas.jpg" alt="Abstract illustration related to banking and fintechs" style={{ width: isSm ? "80px" : "120px", height: isSm ? "80px" : "120px", borderRadius: "100%", objectFit: "cover" }} />
              <Box>
                <Typography variant="subtitle1">Mar 4, 2024</Typography>
                <Typography variant="h3" mt={0.5}>BaaS partner bank landscape 2024</Typography>
                <Box mt={1.5} display="flex" gap="8px" style={{ flexWrap: "wrap", lineHeight: "20px", display: isSm ? "none" : "flex" }} alignItems="center">
                  <Chip label="Baas" />
                  <Chip label="Fintechs" />
                  <Chip label="Partner Banks" />
                </Box>
              </Box>
            </a>

          </Box>
        </Grid>
      </Grid>
      <img src="/images/pattern-zig.svg" width="21" height="100" alt="Abstract pattern for decoration only" className="pattern" style={{ display: isSm ? 'none' : undefined, bottom: "-50px", right: "-5%", zIndex: "0" }} />
    </ResponsiveContainer>

  )
}
