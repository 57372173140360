import {Typography, Button, Box, Grid, Theme, Chip, useMediaQuery} from "@mui/material";
import {ResponsiveContainer} from "../../components/responsive-container";
import {ArrowForward} from "@mui/icons-material";
import {useEffect, ReactNode} from "react";
import {setTitle} from "../../helpers";
import {createUseStyles} from "react-jss";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const useStyles = createUseStyles({
  textLink: {
    borderBottom: "3px solid",
    borderColor: "var(--yellow) !important",
    transition: "all .3s ease",
    cursor: "pointer",
    '&:hover': {
      borderColor: "rgba(28, 26, 31, .2) !important"
    }
  }
})

export function InsightsArticleThree() {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()

  const articleTitle = "How BaaS banks market themselves"

  useEffect(() => {
    setTitle(articleTitle)
    document.body.style.background = "url(/images/bg-white.jpg)"
    document.body.style.backgroundSize = "150px 150px"
    return () => {
      setTitle()
      document.body.style.background = "unset"
      document.body.style.backgroundSize = "unset"
    }
  })

  function scrollToBranding() {
    document.getElementById('branding')?.scrollIntoView({behavior: "smooth"})
  }
  function scrollToContacts() {
    document.getElementById('contacts')?.scrollIntoView({behavior: "smooth"})
  }
  function scrollToCapabilities() {
    document.getElementById('capabilities')?.scrollIntoView({behavior: "smooth"})
  }
  function scrollToAPIs() {
    document.getElementById('apis')?.scrollIntoView({behavior: "smooth"})
  }
  function scrollToClients() {
    document.getElementById('clients')?.scrollIntoView({behavior: "smooth"})
  }
  function scrollToOther() {
    document.getElementById('other')?.scrollIntoView({behavior: "smooth"})
  }

  const createData = (
    id: number, 
    bankName: ReactNode, 
    rating: string, 
    branding: string, 
    website: ReactNode, 
    whatDo: string, 
    keyContacts: string, 
    contactInfo: string, 
    capabilities: string, 
    APIs: ReactNode, 
    referenceClients: string
  ) => {
    return { id, bankName, rating, branding, website, whatDo, keyContacts, contactInfo, capabilities, APIs, referenceClients };
  };
  
  const rows = [
    createData(1, <a href="/banks/cross-river-bank">Cross River Bank</a>, 'Tier 1', '', <a href="https://www.crossriver.com/fintech" target="_blank" rel="noreferrer">View Website</a>, 'Fintech', 'Yes', 'No', 'Yes', <a href="https://www.crossriver.com/developers" target="_blank" rel="noreferrer">Yes</a>, 'Yes'),
    createData(2, <a href="/banks/evolve-bank-and-trust">Evolve Bank & Trust</a>, 'Tier 1', '', <a href="https://www.getevolved.com/openbanking/" target="_blank" rel="noreferrer">View Website</a>, 'Open Banking', 'No', 'No', 'Yes', 'No', 'Yes'),
    createData(3, <a href="/banks/synovus">Synovus Bank</a>, 'Tier 1', 'Maast', <a href="http://www.maast.com/" target="_blank" rel="noreferrer">View Website</a>, 'Money-as-a-service', 'Yes', 'No', 'Yes', <a href="https://developer.maast.com/reference/overview-2" target="_blank" rel="noreferrer">Yes</a>, 'No'),
    createData(4, <a href="/banks/mainstreet-bank">MainStreet Bank</a>, 'Tier 1', 'Avenu', <a href="https://avenu.bank/" target="_blank" rel="noreferrer">View Website</a>, 'Embedded Banking', 'Yes', 'Yes', 'Yes', <a href="https://docs.avenu.bank/docs/getting-started" target="_blank" rel="noreferrer">Yes</a>, 'No'),
    createData(5, <a href="/banks/coastal-community-bank">Coastal Community Bank</a>, 'Tier 1', 'CCBX', <a href="https://www.coastalbank.com/ccbx/" target="_blank" rel="noreferrer">View Website</a>, 'Fintech BaaS', 'Yes', 'Yes', 'Yes', 'No', 'No'),
    createData(6, <a href="/banks/lincoln-savings-bank">Lincoln Savings Bank</a>, 'Tier 1', 'LSBX', <a href="https://www.lsbx.com/" target="_blank" rel="noreferrer">View Website</a>, 'Community BaaS', 'Yes', 'Yes', 'Yes', 'No', 'No'),
    createData(7, <a href="/banks/emprise">Emprise Bank</a>, 'Tier 2', 'Emprise Embedded', <a href="https://www.emprisebank.com/baas/" target="_blank" rel="noreferrer">View Website</a>, 'BaaS', 'Yes', 'No', 'Yes', 'No', 'No'),
    createData(8, <a href="/banks/sutton-bank">Sutton Bank</a>, 'Tier 2', 'Sutton Payments', <a href="https://www.suttonpayments.com/" target="_blank" rel="noreferrer">View Website</a>, 'Payments', 'Yes', 'No', 'Yes', 'No', 'No'),
    createData(9, <a href="/banks/mvb-bank">MVB Bank</a>, 'Tier 2', 'Victor (APIs)', <a href="https://www.mvbbanking.com/fintech/" target="_blank" rel="noreferrer">View Website</a>, 'Fintech', 'No', 'No', 'Yes', <a href="https://docs.victorfi.com/docs/welcome" target="_blank" rel="noreferrer">Yes</a>, 'No'),
    createData(10, <a href="/banks/community-federal-savings-bank">CFSB</a>, 'Tier 2', '', <a href="https://www.cfsb.com/payments/" target="_blank" rel="noreferrer">View Website</a>, 'Lending, Payments', 'No', 'No', 'Yes', <a href="https://www.cfsb.com/developers/" target="_blank" rel="noreferrer">Yes</a>, 'Yes'),
    createData(11, <a href="/banks/pathward-bank">Pathward</a>, 'Tier 2', '', <a href="https://www.pathward.com/banking/" target="_blank" rel="noreferrer">View Website</a>, 'BaaS', 'No', 'No', 'Yes', 'No', 'Yes'),
    createData(12, <a href="/banks/grasshopper">Grasshopper Bank</a>, 'Tier 2', '', <a href="https://www.grasshopper.bank/banking-solutions/baas/" target="_blank" rel="noreferrer">View Website</a>, 'BaaS', 'No', 'No', 'Yes', 'Yes (Treasury Prime)', 'No'),
    createData(13, <a href="/banks/hsbc">HSBC</a>, 'Tier 2', '', <a href="https://develop.hsbc.com/baas" target="_blank" rel="noreferrer">View Website</a>, 'BaaS', 'No', 'No', 'Yes', <a href="https://develop.hsbc.com/apis" target="_blank" rel="noreferrer">Yes</a>, 'Yes'),
    createData(14, <a href="/banks/finwise-bank">Finwise Bank</a>, 'Tier 2', '', <a href="https://www.finwise.bank/strategic-platforms/" target="_blank" rel="noreferrer">View Website</a>, 'Fintech Enablement', 'No', 'No', 'Yes', 'No', 'Yes'),
    createData(15, <a href="/banks/the-bancorp-bank">The Bancorp</a>, 'Tier 2', '', <a href="https://www.thebancorp.com/solution/fintech-solutions/" target="_blank" rel="noreferrer">View Website</a>, 'Fintech Solutions', 'No', 'No', 'Yes', 'No', 'Yes'),
    createData(16, <a href="/banks/celtic-bank">Celtic Bank</a>, 'Tier 3', '', <a href="https://www.celticbank.com/partnerships" target="_blank" rel="noreferrer">View Website</a>, 'Strategic Lending Solutions', 'No', 'No', 'Yes', 'No', 'No'),
    createData(17, <a href="/banks/green-dot">Green Dot</a>, 'Tier 2', '', <a href="https://www.greendot.com/business-solutions" target="_blank" rel="noreferrer">View Website</a>, 'BaaS', 'No', 'No', 'Yes', <a href="https://www.greendot.com/business-solutions/developer" target="_blank" rel="noreferrer">Yes</a>, 'Yes'),
    createData(18, <a href="/banks/lead-bank">Lead Bank</a>, 'Tier 2', '', <a href="https://www.lead.bank/baas-partner-platform" target="_blank" rel="noreferrer">View Website</a>, 'BaaS Partner Platform', 'No', 'No', 'Yes', 'No', 'No'),
    createData(19, <a href="/banks/column-bank">Column Bank</a>, 'Tier 3', '', <a href="https://column.com/" target="_blank" rel="noreferrer">View Website</a>, 'Developer Infrastructure', 'No', 'No', 'Yes', <a href="https://column.com/docs" target="_blank" rel="noreferrer">Yes</a>, 'No'),
    createData(20, <a href="/banks/lewis-and-clark-bank">Lewis &amp; Clark Bank</a>, 'Tier 3', 'BankEvo', <a href="https://www.bankevo.com/" target="_blank" rel="noreferrer">View Website</a>, 'Financial Innovation', 'No', 'No', 'No', 'No', 'No'),
    createData(21, <a href="/banks/piermont-bank">Piermont Bank</a>, 'Tier 3', '', <a href="https://www.piermontbank.com/innovators/" target="_blank" rel="noreferrer">View Website</a>, 'Innovation Banking', 'No', 'No', 'Yes', 'No', 'No'),
    createData(22, <a href="/banks/webbank">WebBank</a>, 'Tier 3', '', <a href="https://www.webbank.com/brand-partners" target="_blank" rel="noreferrer">View Website</a>, 'Brand Partnerships', 'No', 'No', 'No', 'No', 'Yes')
  ];

  return (

    <ResponsiveContainer includePadding>
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12} md={9.5}>
          <Box style={{ maxWidth: "720px" }} className="insights-header">
            <Typography variant="subtitle1" mb={isSm ? 1 : 2}>April 8, 2024</Typography>
            <Typography variant="h1" mb={isSm ? 2.5 : 4}>{articleTitle}</Typography>
            <Box display="flex" gap="8px" style={{ flexWrap: "wrap", lineHeight: "20px" }} alignItems="center">
              <a href="/banks"><Chip label="BaaS Banks" /></a>
              <Chip label="Banking as a Service" />
              <Chip label="Marketing" />
            </Box>
          </Box>
          <Box mt={isSm ? 4 : 5} mb={isSm ? 4 : 7} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative" }}>
            <img src="/insights/baas-marketing.jpg" width="720px" height="480px" alt="Example of a BaaS marketing website" style={{ width: "100%", maxWidth: "720px", height: "auto" }} />
            <Box style={{ display: isSm ? "none" : undefined, maxWidth: "160px" }}>
              <Typography variant="body2" mb={1.5}><a href="/insights/baas-bank-partner-landscape">BaaS bank partner landscape</a></Typography>
              <a href="/insights/baas-bank-partner-landscape">
                <Button variant='text' style={{ marginLeft: "-10px" }}>
                  <ArrowForward/>
                  Next Insight
                </Button>
              </a>
            </Box>
            <img src="/images/pattern-circle-1.svg" width="160" height="160" alt="Abstract pattern for decoration only" className="pattern" style={{ top: isSm ? "80px" : "245px", left: "-80px" }} />
          </Box>
          <Box className="article-body">
            <Box style={{ padding: "24px 24px 0 24px", borderRadius: "16px", background: "url(/images/bg-alt.jpg) 150px 150px", marginBottom: "32px" }}>
              <Typography variant="body2"><em>Welcome to <a href="/">Banking Embedded</a>. Your go-to fintech intelligence resource. We map and track the fintech ecosystem in the US and internationally, keeping up with trends and aggregating the data in ways that make it easier to understand how the pieces of the fintech puzzle are put together.</em></Typography>
            </Box>
            <p>In our last piece, we covered the <a href="/insights/baas-bank-partner-landscape">BaaS Partner Bank Landscape</a>. It is clear in the data that there are <a href="/banks">banks</a> that specialize in BaaS - embedded demand deposit accounts in particular - and those banks are hoovering up billions of dollars of deposits with their <a href="/companies">fintech partners</a>. While the regulatory headwinds may have caused some banks to hit pause (see <a href="/banks/lineage-bank">Lineage</a> or <a href="/banks/blue-ridge-bank">Blue Ridge</a>), the specialist BaaS banks are increasingly going to be competing fiercely for these deposits. And they likely won't be alone. As Matt West from Xenios points out - <a href="/insights/nasa-baas-banks">new entrants to BaaS have a huge advantage over incumbents</a>.</p>
            <p>So as this competition heats up, there are a variety of ways banks can compete. There are obviously core capabilities that banks must have: compliance expertise, developer experience (APIs, SDK's, etc.), capabilities (virtual cards, push-to-card, etc.), etc. but they are also in a battle for awareness amongst companies actively looking for a BaaS sponsor.</p>
            <p>Truth be told, today, most current BaaS banks have way more demand than they can support. Even high quality programs are being turned down by banks that are fully committed to existing partnerships for the time being. As a result, there are high-quality partners still looking for BaaS banks.</p>
            <p>In the last few weeks, several startup founders and vertical SaaS companies reached out about how to select a <a href="/banks">partner bank</a>. So I placed myself in their shoes and asked: 'If you are doing sponsor bank research, is it clear whether the bank is a good fit for what you are building?'</p>
            <p>Today we are going to look at the variety of ways that partner banks market their BaaS services <em>(click the links below to scroll to each section)</em>:</p>
            <ol>
              <li><strong className={classes.textLink} onClick={scrollToBranding}>Branding</strong>: Do they do BaaS under their own brand or a separate brand?</li>
              <li><strong className={classes.textLink} onClick={scrollToContacts}>Key contacts</strong>: Do they identify the key members of the team to reach out to with contact information?</li>
              <li><strong className={classes.textLink} onClick={scrollToCapabilities}>Capabilities</strong>: Are they clear about what their capabilities are?</li>
              <li><strong className={classes.textLink} onClick={scrollToAPIs}>APIs</strong>: Do they expose APIs so developers can quickly see if the APIs might meet their needs?</li>
              <li><strong className={classes.textLink} onClick={scrollToClients}>Reference clients</strong>: Do they list some example clients or focus areas / industries of expertise?</li>
              <li><strong className={classes.textLink} onClick={scrollToOther}>Other notable elements</strong> (forms, pricing, compliance, and customer service).</li>
            </ol>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 504 }}>
                <Table stickyHeader sx={{ minWidth: 1300 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Bank name</TableCell>
                      <TableCell>Rating*</TableCell>
                      <TableCell>Branding</TableCell>
                      <TableCell>Website</TableCell>
                      <TableCell>What do they call it?</TableCell>
                      <TableCell>Key contacts</TableCell>
                      <TableCell>Contact info</TableCell>
                      <TableCell>Capabilities</TableCell>
                      <TableCell>APIs</TableCell>
                      <TableCell>Reference clients</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">{row.bankName}</TableCell>
                        <TableCell>{row.rating}</TableCell>
                        <TableCell>{row.branding}</TableCell>
                        <TableCell>{row.website}</TableCell>
                        <TableCell>{row.whatDo}</TableCell>
                        <TableCell>{row.keyContacts}</TableCell>
                        <TableCell>{row.contactInfo}</TableCell>
                        <TableCell>{row.capabilities}</TableCell>
                        <TableCell>{row.APIs}</TableCell>
                        <TableCell>{row.referenceClients}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Typography variant="subtitle1" mb={4}><em>* Note: The “Rating” above is our assessment of the quality of their website marketing, not intended to be an assessment of their competence, capability or anything else other than their web marketing.</em></Typography>
            <p>Below are examples to highlight the best of breed in each of the above categories:</p>
            <h2 id="branding" className="scrollTo">A dedicated brand for BaaS</h2>
            <Box className="article-image" style={{ paddingBottom: "24px" }}>
              <img src="/insights/baas-marketing-brands.jpg" width="720px" height="482px" alt="Examples of banking as a service branding" />
            </Box>
            <ul>
              <li><strong><a href="/banks/lincoln-savings-bank">Lincoln Savings Bank</a> (LSBX)</strong> have an entirely different brand <a href="https://www.lsbx.com/" target="_blank" rel="noreferrer">and website</a> for their BaaS division, which they call LSBX. It is well-executed and effective.</li>
              <li><strong><a href="/banks/coastal-community-bank">Coastal Community Bank</a> (CCBX)</strong> went the same way as LSBX with a dedicated brand - CCBX - but they included CCBX directly <a href="https://www.coastalbank.com/ccbx/" target="_blank" rel="noreferrer">within their broader community bank web experience</a>.</li>
              <li><strong><a href="/banks/mainstreet-bank">MainStreet Bank</a> (Avenu)</strong> — a relatively new entrant to BaaS — created an <a href="https://www.avenu.bank/" target="_blank" rel="noreferrer">entirely new brand experience</a> for its fintech business.</li>
              <li><strong><a href="/banks/synovus">Synovus</a> (Maast)</strong> also went the route of creating a dedicated brand <a href="https://maast.com/" target="_blank" rel="noreferrer">and website</a> for its BaaS business, which it calls Maast.</li>
            </ul>
            <h2 id="contacts" className="scrollTo">Key contacts</h2>
            <Box className="article-image" style={{ paddingBottom: "24px" }}>
              <img src="/insights/baas-marketing-key-contacts.jpg" width="720px" height="269px" alt="Examples of banking as a service key contacts" />
            </Box>
            <ul>
              <li><strong><a href="https://www.emprisebank.com/baas/" target="_blank" rel="noreferrer">Emprise Embedded</a></strong> features high-quality headshots of smiling bankers with a quick path to connect on LinkedIn. I wish every BaaS bank did this, though there are some obvious drawbacks to the strategy. In speaking with bankers recently, many say they can quickly get flooded with inbound from companies looking for sponsors, with many of those potential partner not truly prepared for the conversation.</li>
              <li><strong><a href="/banks/mainstreet-bank">MainStreet Bank</a> (Avenu)</strong> lists their full executive team (with emails!) directly on the <a href="https://www.avenu.bank/" target="_blank" rel="noreferrer">Avenu website</a>. I love this. I just don't love the greenscale on the images.</li>
            </ul>
            <h2 id="capabilities" className="scrollTo">Capabilities</h2>
            <Box className="article-image" style={{ paddingBottom: "24px" }}>
              <img src="/insights/baas-marketing-capabilities.jpg" width="720px" height="238px" alt="Examples of banking as a service capabilities" />
            </Box>
            <ul>
              <li><strong><a href="/banks/continental-bank">Continental Bank</a></strong> provides a comprehensive list about their services, listing their detailed lending and deposit capabilities.</li>
              <li><strong><a href="/banks/coastal-community-bank">Coastal Community Bank</a></strong> takes the more standard approach I see banks take, which is to list the high-level buckets that they support, but leave out much of the detail.</li>
            </ul>
            <h2 id="apis" className="scrollTo">APIs</h2>
            <Box className="article-image" style={{ paddingBottom: "24px" }}>
              <img src="/insights/baas-marketing-apis.jpg" width="720px" height="269px" alt="Examples of banking as a service API documentation" />
            </Box>
            <ul>
              <li><strong><a href="/banks/cross-river-bank">Cross River Bank</a></strong>'s APIs are the most robust and operationally mature. <a href="https://docs.crossriver.com/" target="_blank" rel="noreferrer">The documentation</a> is clear, there are some handy ways to search and navigate them easily.</li>
              <li><strong><a href="/banks/column-bank">Column Bank</a></strong>'s APIs are powerful and simple. Column also adds all the developer-friendly features you would expect from one of Plaid's co-founders: starter videos, uptime monitoring, incident history, a robust changelog . . . and even an offer to email the CEO directly with feedback. If <a href="/banks/cross-river-bank">Cross River Bank</a> is the East Coast 10,000 pound gorilla, then <a href="/banks/column-bank">Column Bank</a> is the nimble West Coast upstart. <a href="https://www.youtube.com/watch?v=clR179gIxr8&t=17s" target="_blank" rel="noreferrer">View a demo of Column's API docs here</a>.</li>
            </ul>
            <p>Frankly, we were a bit surprised at the state of the API marketplace for <a href="/banks">banks</a>. There should be 20-25 banks that have public APIs, but there are only a small handful. I anticipate and expect that to change over the next few years.</p>
            <h2 id="clients" className="scrollTo">Reference clients</h2>
            <Box className="article-image" style={{ paddingBottom: "24px" }}>
              <img src="/insights/baas-marketing-clients.jpg" width="720px" height="269px" alt="Examples of banking as a service referencing their fintech partners and clients" />
            </Box>
            <ul>
              <li><strong><a href="/banks/evolve-bank-and-trust">Evolve Bank &amp; Trust</a></strong> list 15 of their partners in alphabetical order, so powerhouses like <a href="/companies/mercury">Mercury</a> and <a href="/companies/melio">Melio</a> show up on <a href="https://www.getevolved.com/openbanking/" target="_blank" rel="noreferrer">page 2 of their partner list</a>.</li>
              <li><strong><a href="/banks/cross-river-bank">Cross River Bank</a></strong>'s partner list is extensive and they only list a few logos here. For those looking to really dig in they more than make up for the limited list with <a href="https://www.crossriver.com/newsroom" target="_blank" rel="noreferrer">a comprehensive newsroom</a>.</li>
              <li><strong><a href="/banks/the-bancorp-bank">Bancorp Bank</a></strong> has some amazing fintech programs and offers a well-balanced web experience hitting most of the highlights you would expect from a <a href="/banks">sponsor bank</a>.</li>
              <li><strong><a href="/banks/webbank">WebBank</a></strong> have a dazzling array of partners and showcase super well, and likely have the most to gain from a revived web experience. Though, <a href="https://www.webbank.com/brand-partners" target="_blank" rel="noreferrer">from the looks of it</a>, they are not having any problems with business development.</li>
            </ul>
            <h2 id="other" className="scrollTo">Other notable elements</h2>
            <Box className="article-image" style={{ paddingBottom: "24px" }}>
              <img src="/insights/baas-marketing-other.jpg" width="720px" height="447px" alt="Examples of banking as a service marketing" />
            </Box>
            <ul>
              <li><strong>Forms</strong>: Almost every bank has a form for interested parties to fill out. Most are very simple, with fields like name, company name, email address and a free text field. But one bank - <a href="/banks/celtic-bank">Celtic Bank</a> - actually has a robust set of questions across product, servicing and compliance that likely helps them truly qualify a partner before spending time discussing it with them. I think there is a good lesson in this and an opportunity.</li>
              <li><strong>Pricing</strong>: <a href="/banks/column-bank">Column Bank</a> is the ONLY bank in our small sample that lists pricing directly on their website . . . I have to admit that is pretty cool. Now, I recognize that there are other costs associated with working with Column, and they likely have minimums that partners have to hit, but its just cool to see banking operating similar to other API-driven businesses.</li>
              <li><strong>Compliance</strong>: If you are looking for a partner bank and are not asking them about their approach to compliance you are doing it wrong. Compliance is at the core of success in fintech banking. So, I found it interesting that buried in their API docs, <a href="/banks/mvb-bank">MVB Bank</a> (<a href="https://victorfi.com/" target="_blank" rel="noreferrer">Victor</a>) has a detailed overview and set of screenshots about their homegrown compliance tool - Vantage. Featuring onboarding a new fintech partner to detailed risk assessment automation tools. In my last piece on the <a href="/insights/baas-bank-partner-landscape">BaaS Partner Bank Landscape</a>, I referenced how the top banks are investing in people - but also technology. Well, look no further. If you are a BaaS bank and still doing ongoing program task management and coordination in excel and trying to manage onboarding in word, you are falling behind. The MVB Bank's of the world are building full-fledged software applications in-house to manage their BaaS book.</li>
              <li><strong>Customer service</strong>: While not the only bank to mention it, <a href="/banks/lead-bank">Lead Bank</a> hits the nail on the head here. I've heard this sentiment expressed from other always-on founder and developers who are working around the clock to innovate on their fintech applications, only to become disgruntled that their bank partner just doesn't seem to work like they do. Don't under-estimate the power of this message:</li>
            </ul>
            <Box mt={4} display="flex" alignItems="center" gap="12px">
              <img src="/avatars/ryan-alfred.jpg" width="56" height="56" alt="Ryan Alfred" style={{ borderRadius: "100%" }} />
              <Box>
                <Typography variant="h5" style={{ padding: "0" }}>Ryan Afred</Typography>
                <Typography variant="subtitle1">Founder, Embedded</Typography>
              </Box>
              <a href="https://www.linkedin.com/in/ryanalfred/" target="_blank" rel="noreferrer" className="icon-button icon-linkedin" style={{ marginLeft: "56px" }}>LinkedIn</a>
            </Box>
          </Box>
        </Grid>
        <img src="/images/pattern-triangle-1.svg" width="160" height="139" alt="Abstract pattern for decoration only" className="pattern" style={{ top: "500px", right: "-80px", display: isSm ? "none" : undefined }} />
      </Grid>
    </ResponsiveContainer>

  )
}
