import {Box, Button, Grid, Theme, Typography, useMediaQuery} from "@mui/material";
import {ResponsiveContainer} from "../../components/responsive-container";

export function Hero() {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  function scrollToFooter() {
    document.getElementById('footer')?.scrollIntoView({ behavior: "smooth" })
  }

  return (

    <ResponsiveContainer>
      <Grid container display="flex" justifyContent="center" mt={5} mb={ isSm ? 5 : 10 }>
        <Grid item xs={12} md={9.5} display="flex" justifyContent={isSm ? undefined : 'space-between'} alignItems="center" flexDirection={isSm ? 'column' : 'row-reverse'}>
          <img src="/images/illustration-home.png" width="600" height="400" alt="Abstract illustration  of people working in fintech" style={{ width: "100%", maxWidth: "600px", height: "auto", marginRight: isSm ? undefined : '-12%', marginBottom: isSm ? '32px' : undefined }} />
          <Box style={{ maxWidth: "400px" }}>
            <Typography variant="h1" mb={1}>Embedded banking</Typography>
            <Typography mt={ isSm ? 1 : 2 } mb={ isSm ? 2 : 3 }>Are you looking to better understand the embedded finance landscape? We can help – with data, insights, research, and connections.</Typography>
            <Button variant="outlined" onClick={scrollToFooter}>Get in Touch</Button>
          </Box>
        </Grid>
      </Grid>
    </ResponsiveContainer>

  )
}
