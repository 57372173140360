import {Box, Theme, Button, useMediaQuery} from "@mui/material";
import {ReactNode, useEffect, useState} from "react";
import {createUseStyles} from "react-jss";

export interface PageSection {
  title: string
  elementId?: string
  section?: ReactNode
}

const useStyles = createUseStyles({
  stickyNav: {
    position: 'fixed',
    top: '-56px',
    left: 0,
    width: '100%',
    height: '56px',
    background: 'var(--black)',
    transition: 'all .5s ease',
    overflowX: 'scroll',
    zIndex: "9999",
    '&.stick': {
      top: 0,
    },
    '& ul': {
      listStyle: 'none',
      display: 'flex',
      fontSize: '12px',
      lineHeight: '20px',
      padding: "0",
      margin: '0 24px',
    },
    '& li': {
      textWrap: 'nowrap',
    },
    '& a': {
      cursor: 'pointer',
    },
    '& button': {
      fontSize: "12px",
      lineHeight: "20px",
      fontWeight: "400",
      color: "var(--white)",
      borderColor: "rgba(255, 255, 255, .2)",
      minWidth: "auto"
    },
    '& button:hover': {
      color: "var(--white)",
      backgroundColor: "rgba(255, 255, 255, .1)",
      boxShadow: "0 0 0 2px rgba(255, 255, 255, .1)"
    }
  }
})

export function StickyNav({sections}: { sections: PageSection[] }) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setShowHeader(window.scrollY > 800);
    });
  }, []);

  function scrollToSection(s: PageSection) {
    if (s.elementId) {
      document.getElementById(s.elementId)?.scrollIntoView({behavior: "smooth"})
    } else {
      window.scrollTo({top: 0, behavior: "smooth"})
    }
  }

  return (

    <Box className={`sticky-nav ${classes.stickyNav} ${showHeader ? "stick" : ""}`} display="flex" alignItems="center" justifyContent="center">
      <ul style={{ gap: isSm ? "0" : "16px" }}>
        {sections.map((s, i) => (
          <li key={i}>
            <Button variant="text" onClick={() => scrollToSection(s)}>{s.title}</Button>
          </li>
        ))}
      </ul>
    </Box>
  )
}
